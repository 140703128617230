export const terms = [
  {
    id: 1,
    name: "개인정보 처리방침",
    required: true,
    content: `제1조 (목적)
본 방침은 PLWIDA(이하 "회사"라 함)가 제공하는 '마이스토리지' 서비스(이하 "서비스"라 함)를 이용하는 이용자의 개인정보를 보호하기 위해 회사가 수립한 개인정보 처리방침을 설명합니다.

제2조 (개인정보의 수집 항목 및 방법)
회사는 서비스 제공을 위해 다음과 같은 개인정보를 수집할 수 있습니다.
:필수항목: 이름, 이메일 주소, 연락처, 결제 정보 선택 항목: 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보개인정보는 다음과 같은 방법으로 수집됩니다. 또한 서비스 이용 신청, 회원 가입, 고객 상담, 온라인 문의 등을 통해 이용자가 직접 제공 자동화된 수집 도구를 통한 수집

제3조 (개인정보의 수집 및 이용 목적)
회사는 수집한 개인정보를 다음의 목적을 위해 이용합니다:
서비스 제공 및 운영 고객 관리 및 상담 서비스 개선 및 개발 마케팅 및 광고에의 활용법적 의무 준수

제4조 (개인정보의 보유 및 이용 기간)
회사는 개인정보 수집 및 이용 목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 관련 법령에 따라 일정 기간 보관해야 하는 정보는 법령에서 정한 기간 동안 보관합니다.

제5조 (개인정보의 제3자 제공)
회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다:이용자가 사전에 동의한 경우법령에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우

제6조 (개인정보의 처리 위탁)
회사는 원활한 업무 처리를 위해 개인정보 처리를 외부에 위탁할 수 있으며, 이 경우 위탁받는 자와 위탁 업무의 내용을 공개합니다.

제7조 (이용자의 권리와 의무)
이용자는 언제든지 자신의 개인정보를 조회하거나 수정할 수 있으며, 수집 및 이용에 대한 동의를 철회할 수 있습니다. 이용자는 개인정보의 정확성을 유지할 의무가 있으며, 부정확한 정보 제공으로 발생하는 문제에 대해서는 이용자가 책임을 집니다.

제8조 (개인정보의 안전성 확보 조치)
회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취합니다:
개인정보의 암호화 해킹 등에 대비한 기술적 대책 개인정보 접근 제한 개인정보 처리 직원의 최소화 및 교육

제9조 (개인정보 보호 책임자)
회사는 개인정보 보호 책임자를 지정하여 개인정보 보호 업무를 총괄하며, 이용자의 개인정보 관련 문의에 대해 신속하고 성실하게 답변합니다.
개인정보 보호 책임자: 전영욱
이메일: [atad@atad.ai](mailto:atad@atad.ai) / [brad@atad.ai](mailto:brad@atad.ai), 담당자 연락처: 1600-8424 (010-7524-6777)

제10조 (개인정보 처리방침의 변경)
본 방침은 시행일로부터 적용되며, 법령 및 회사 정책에 따라 변경될 수 있습니다. 변경된 방침은 서비스 화면에 게시하거나 기타의 방법으로 공지함으로써 효력이 발생합니다.`,
  },
  {
    id: 2,
    name: "저작권 정책",
    required: true,
    content: `1. 저작권 소유
본 서비스에서 제공되는 모든 콘텐츠(텍스트, 이미지, 비디오, 오디오, 소프트웨어 등)는 회사의 소유이며, 저작권법에 의해 보호받습니다.콘텐츠 사용에 대한 모든 권리는 회사에 있으며, 회사의 명시적인 서면 동의 없이 무단으로 복제, 배포, 수정, 전송, 게시할 수 없습니다.

2. 사용 허가
사용자들은 개인적이고 비상업적인 용도로만 본 서비스의 콘텐츠를 사용할 수 있습니다. 이 경우에도 콘텐츠의 출처를 명확히 밝혀야 합니다.상업적 목적으로 콘텐츠를 사용하고자 할 경우, 사전에 회사의 서면 허가를 받아야 합니다.

3. 사용자 생성 콘텐츠
사용자가 본 서비스에 업로드하거나 게시하는 콘텐츠는 사용자가 저작권을 소유하고 있거나 사용 권한을 보유하고 있어야 합니다.사용자는 본 서비스에 콘텐츠를 업로드함으로써 회사에 해당 콘텐츠를 전 세계적으로, 비독점적으로, 로열티 없이 사용할 수 있는 권한을 부여합니다. 이는 회사가 해당 콘텐츠를 복제, 배포, 공개, 수정, 표시할 수 있는 권한을 포함합니다.

4. 저작권 침해 신고
회사는 타인의 저작권을 존중하며, 사용자들도 타인의 저작권을 침해해서는 안 됩니다.만약 사용자가 저작권 침해를 주장하는 콘텐츠를 발견하면, 다음의 정보를 포함하여 저작권 침해 신고를 할 수 있습니다:침해된 저작물의 설명침해를 주장하는 콘텐츠의 URL 또는 위치저작권 소유자의 연락처 정보(이름, 전화번호, 이메일 주소)저작권 소유자 또는 그 대리인의 서명(전자 서명 포함)침해 주장 내용이 사실임을 선언하는 진술서

5. 저작권 침해에 대한 조치
회사는 저작권 침해 신고를 검토하고, 필요한 경우 해당 콘텐츠를 제거하거나 접근을 차단할 것입니다.반복적인 저작권 침해자는 서비스 이용이 제한될 수 있습니다.

6. 면책 조항
사용자가 본 서비스에 업로드한 모든 콘텐츠에 대한 책임은 전적으로 사용자에게 있습니다. 회사는 사용자 콘텐츠의 법적 문제에 대해 책임을 지지 않습니다.

7. 변경 사항
회사는 저작권 정책을 수시로 수정할 수 있으며, 중요한 변경 사항이 있을 경우 공지사항을 통해 알릴 것입니다. 변경된 정책은 공지된 날로부터 효력이 발생합니다.

8. 문의
저작권 관련 문의 사항은 고객 지원팀으로 연락해 주세요.
이메일: [atad@atad.ai](mailto:atad@atad.ai)  전화:1600-8424`,
  },
  {
    id: 3,
    name: "환불 정책",
    required: true,
    content: `1. 환불 요청 기간
제품 구매 시: 제품 수령 후 14일 이내에🙆‍♂️ 환불 요청을 하실 수 있습니다.서비스 구매 시: 서비스 이용 시작 후 7일 이내에 🙆‍♂️환불 요청을 하실 수 있습니다.

2. 환불 조건
제품 환불: 제품이 사용되지 않은 상태여야 하며, 원래의 포장 상태를 유지해야 합니다. 제품의 결함이나 손상이 없는 경우에만 환불이 가능합니다.서비스 환불: 서비스가 이미 제공된 경우에는 환불이 불가능합니다. 단, 서비스의 품질에 문제가 있는 경우에는 예외적으로 환불이 가능합니다.

3. 환불 절차
제품 환불 절차:
    1. 고객 지원팀에 환불 요청을 접수합니다.제품을 반송 주소로 배송합니다.제품 검수 후 환불이 승인되면, 결제 수단으로 환불 금액이 반환됩니다.서비스 환불 절차:
    1. 고객 지원팀에 환불 요청을 접수합니다.서비스 이용 내역을 확인 후 환불이 승인되면, 결제 수단으로 환불 금액이 반환됩니다.

4. 환불 불가 항목
디지털 상품: 다운로드된 디지털 콘텐츠는 환불이 불가능합니다.맞춤 제작 상품: 고객의 요구에 따라 맞춤 제작된 제품은 환불이 불가능합니다.세일 상품: 세일 또는 할인된 제품은 환불이 불가능합니다.

5. 배송비
고객의 변심으로 인한 반품의 경우, 반품 배송비는 고객 부담입니다.제품의 결함이나 오배송으로 인한 반품의 경우, 반품 배송비는 회사가 부담합니다.🙆‍♂️

6. 환불 처리 시간
환불 요청이 승인된 후, 환불 처리에는 영업일 기준으로 7-10일🙆‍♂️이 소요될 수 있습니다.

7. 문의
환불과 관련된 문의 사항은 고객 지원팀으로 연락해 주세요.
이메일: [atad@atad.ai](mailto:atad@atad.ai)  전화:1600-8424`,
  },
  {
    id: 4,
    name: "면책 조항",
    required: true,
    content: `정보 제공
당사는 서비스에서 제공하는 모든 정보의 정확성과 신뢰성을 유지하기 위해 최선을 다하고 있으나, 정보의 완전성, 정확성, 최신성에 대해 보장하지 않습니다. 사용자는 제공된 정보를 신뢰하기 전에 추가적인 확인을 해야 합니다.

서비스 이용
당사는 서비스 이용과 관련하여 발생할 수 있는 직접적, 간접적, 부수적, 특별 또는 결과적 손해에 대해 책임을 지지 않습니다. 이는 데이터 손실, 이익 손실, 비즈니스 중단 등을 포함합니다.

외부 링크
당사의 서비스는 제3자 웹사이트로 연결되는 링크를 포함할 수 있습니다. 당사는 이러한 외부 사이트의 콘텐츠나 개인정보 보호 관행에 대해 책임을 지지 않습니다. 사용자는 외부 사이트를 이용하기 전에 해당 사이트의 약관과 정책을 검토해야 합니다.

기술적 문제
당사는 서비스의 지속적이고 안정적인 제공을 위해 노력하고 있지만, 기술적 문제나 유지보수로 인해 서비스가 일시적으로 중단될 수 있습니다. 이러한 경우 당사는 최대한 신속하게 문제를 해결하기 위해 노력할 것입니다.

사용자의 책임
사용자는 서비스 이용 시 자신의 계정 정보와 비밀번호를 안전하게 관리해야 하며, 이를 제3자에게 공유해서는 안 됩니다. 사용자의 부주의로 인한 계정 도용이나 손해에 대해 당사는 책임을 지지 않습니다.

법적 고지
본 면책 조항은 관련 법률이 허용하는 최대 한도 내에서 적용됩니다. 일부 관할 구역에서는 특정 책임 제한이 허용되지 않을 수 있으므로, 이러한 제한이 해당 사용자에게 적용되지 않을 수 있습니다.`,
  },
  {
    id: 5,
    name: "데이터 클라우드 서비스 약관",
    required: true,
    content: `제1조 (목적)
본 약관은 PLWIDA(이하 "회사"라 함)가 제공하는 '마이스토리지' 서비스(이하 "서비스"라 함)의 이용 조건 및 절차, 이용자와 회사의 권리·의무 및 책임 사항을 규정함을 목적으로 합니다.

제2조 (정의)
"서비스"란 회사가 제공하는 데이터 보관 및 KURV에 유통하는 클라우드 관련 서비스를 의미합니다. "이용자"란 본 약관에 동의하고 서비스를 이용하는 자를 의미합니다.

제3조 (약관의 효력 및 변경)
본 약관은 서비스 화면에 게시하거나 기타의 방법으로 이용자에게 공지함으로써 효력이 발생합니다. 회사는 필요시 약관을 변경할 수 있으며, 변경된 약관은 제1항과 같은 방법으로 공지함으로써 효력이 발생합니다.

제4조 (서비스의 제공 및 변경)
회사는 다음과 같은 서비스를 제공합니다:
    1. 데이터 보관 서비스데이터의 KURV 유통 서비스회사는 운영상, 기술상의 필요에 따라 제공하는 서비스의 내용을 변경할 수 있으며, 이 경우 변경된 서비스의 내용 및 제공 일자를 공지합니다.

제5조 (서비스 이용 제한)
회사는 이용자가 본 약관을 위반하거나 불법, 부당한 행위를 할 경우 서비스 이용을 제한하거나 중지할 수 있습니다.

제6조 (이용자의 의무)
이용자는 서비스를 이용함에 있어 다음 각 호의 행위를 하지 않아야 합니다:
    1. 타인의 정보 도용서비스의 운영을 방해하는 행위불법적인 데이터 보관 및 유통기타 법령에 위반되는 행위

제7조 (데이터 보안 및 책임)
회사는 이용자의 데이터를 보호하기 위해 최선을 다하며, 필요한 보안 조치를 취합니다. 이용자는 자신의 데이터 관리에 대한 책임을 가지며, 불법적인 데이터 보관 및 유통으로 인한 책임은 이용자에게 있습니다.

제8조 (서비스 이용 요금)
서비스 이용 요금은 회사가 정한 바에 따르며, 이용자는 서비스 이용에 따른 요금을 적시에 지불해야 합니다.요금 미납 시 회사는 서비스 이용을 제한할 수 있습니다.

제9조 (면책 조항)
회사는 천재지변, 전쟁, 테러, 해킹 등의 불가항력적인 사유로 인한 서비스 중단이나 데이터 손실에 대해 책임을 지지 않습니다. 회사는 이용자의 귀책 사유로 인한 서비스 이용 장애에 대해 책임을 지지 않습니다.

제10조 (분쟁 해결)
본 약관과 관련된 분쟁은 대한민국 법률에 따르며, 관할 법원은 서울중앙지방법원🙆‍♂️으로 합니다.
    `,
  },
  {
    id: 6,
    name: "쿠키 정책",
    required: false,
    content: `1. 우리가 수집하는 쿠키 종류
필수 쿠키: 웹사이트의 기본 기능을 제공하는 데 필요합니다. 사용자가 사이트를 탐색하고 기능을 사용할 수 있도록 돕습니다.성능 쿠키: 사용자의 방문 및 트래픽 소스를 계산하여 웹사이트 성능을 분석하고 개선하는 데 사용됩니다.기능 쿠키: 사용자의 선택(예: 언어 설정 및 지역 설정)을 기억하여 맞춤형 기능을 제공합니다.광고 쿠키: 사용자가 관심을 가질 만한 광고를 제공하기 위해 사용자의 웹 탐색 습관을 추적합니다.

2. 쿠키 사용 목적
웹사이트 기능 제공 및 유지 사용자 경험 맞춤화 웹사이트 성능 분석 및 개선 타겟 광고 제공

3. 쿠키 관리 방법
대부분의 웹 브라우저는 자동으로 쿠키를 허용하지만, 사용자는 브라우저 설정을 변경하여 쿠키를 거부하거나 삭제할 수 있습니다. 다만, 쿠키를 비활성화하면 웹사이트의 일부 기능을 사용할 수 없게 될 수 있습니다.쿠키 설정 변경 방법: 사용 중인 웹 브라우저의 설정 메뉴에서 '쿠키' 옵션을 찾아 변경할 수 있습니다.

4. 타사 쿠키
당사는 제3자 서비스 제공자의 쿠키를 사용할 수 있습니다. 이들 쿠키는 제3자가 관리하며, 해당 제3자의 쿠키 정책에 따라 사용됩니다. 예를 들어, Google Analytics를 통해 웹사이트 트래픽을 분석할 수 있습니다.

5. 쿠키 정책 변경
회사는 쿠키 정책을 수시로 변경할 수 있으며, 중요한 변경 사항이 있을 경우 웹사이트를 통해 공지할 것입니다. 변경된 정책은 공지된 날로부터 효력이 발생합니다.

6. 문의
쿠키 정책에 대한 문의 사항은 고객 지원팀으로 연락해 주세요.

이메일: [atad@atad.ai](mailto:atad@atad.ai)  전화:1600-8424`,
  },
  {
    id: 7,
    name: "마케팅 및 광고 수신 동의",
    required: false,
    content: `수집 및 이용 목적
마케팅 정보 제공: 새로운 서비스 및 제품 정보, 이벤트 및 프로모션 소식 전달.맞춤형 광고: 사용자의 관심사에 맞춘 맞춤형 광고 제공.
수집하는 개인정보 항목: 이메일 주소, 전화번호, 이름 등🙆‍♂️

제공 방법
이메일: 뉴스레터, 프로모션 이메일.SMS/MMS: 문자 메시지를 통한 이벤트 및 프로모션 소식.앱 푸시 알림: 모바일 앱을 통한 푸시 알림.전화: 전화 마케팅.
개인정보 보유 및 이용 기간

동의를 철회할 때까지 보유 및 이용합니다. 사용자는 언제든지 마케팅 및 광고 수신 동의를 철회할 수 있습니다.
동의 철회 방법
이메일 수신 거부 링크 클릭.고객 지원팀에 연락하여 철회 요청.모바일 앱 설정에서 푸시 알림 해제.`,
  },
];
