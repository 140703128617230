import { Button } from "_components/Button";
import Typography from "_components/Typography";
import useLoginSignUp from "_hooks/use-login-sign-up";
import Lottie from "lottie-react";
import { useRouter } from "next/navigation";
import styled from "styled-components";

import SetupCompleteLottie from "./SetupCompleteLottie.json";

const SetupComplete = (props: any) => {
  const { setTab } = useLoginSignUp();
  const router = useRouter();
  return (
    <S.Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "28px",
      }}
    >
      <div style={{ width: "100px", height: "100px" }}>
        <Lottie
          animationData={SetupCompleteLottie}
          loop={true}
          autoplay={true}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <S.Title variant="title.200_sb">회원가입이 완료되었습니다.</S.Title>
        <S.SubTitle variant="body.200" color="text_10">
          PLWIDA를 이용해주셔서 감사합니다.
        </S.SubTitle>
      </div>
      <S.ButtonContainer>
        <Button
          variant="default"
          size="md"
          width="168px"
          onClick={() => {
            router.push("/");
            setTab("login");
          }}
        >
          <Typography variant="body.200" color="text_60">
            로그인
          </Typography>
        </Button>
      </S.ButtonContainer>
    </S.Container>
  );
};

export default SetupComplete;

const S = {
  Container: styled("div")(() => ({})),

  Title: styled(Typography)(() => ({})),

  SubTitle: styled(Typography)(() => ({})),

  ButtonContainer: styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  })),
};
