import { icons } from "_styles/shared/icons";
import Image from "next/image";

interface SnackBarIconProps {
  variant: "success" | "error" | "warning" | "info" | "default";
}

const iconMap = new Map<SnackBarIconProps["variant"], string>([
  ["success", icons.check.checked_20],
  ["error", icons.error.error_20],
  ["warning", icons.warning.warning_20],
  ["info", icons.info.info_20],
]);

const SnackBarIcon = (props: SnackBarIconProps) => {
  const { variant } = props;

  if (variant === "default") return null;

  const iconSrc = iconMap.get(variant) || iconMap.get("default")!;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "8px",
      }}
    >
      <Image alt={variant} src={iconSrc} width={20} height={20} />
    </div>
  );
};

export default SnackBarIcon;
