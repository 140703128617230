import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import SearchInput from "_components/Input/SearchInput";
import Typography from "_components/Typography";
import { logout } from "_libs/fetcher/auth";
import colorToken from "_styles/colorToken";
import { icons } from "_styles/shared/icons";
import Image from "next/image";
import styled from "styled-components";

const Header = () => {
  const { control } = useForm();
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLogout = async () => {
    await logout();
    window.location.reload();
  };

  return (
    <S.Header>
      {/* search */}
      <S.Search>
        <Controller
          name="search"
          control={control}
          render={({ field }) => (
            <SearchInput
              placeholder="찾으시는 내용을 검색해 보세요."
              value={field.value}
              onChange={field.onChange}
              fullWidth
            />
          )}
        />
      </S.Search>

      {/* menu */}
      <S.Menu>
        <Image alt="" src={icons.bell} width={24} height={24} />
        <Image
          alt=""
          src={icons.profile.profile_44}
          width={44}
          height={44}
          onClick={toggleMenu}
        />
        {menuVisible && (
          <S.DropdownMenu>
            <S.MenuItem onClick={handleLogout}>
              <Typography variant="caption.100" color="text_5">
                로그아웃
              </Typography>
            </S.MenuItem>
          </S.DropdownMenu>
        )}
      </S.Menu>
    </S.Header>
  );
};

const S = {
  Header: styled("div")(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid var(--line_10)",
    padding: "12px 20px",
    backgroundColor: "var(--white)",
  })),
  Menu: styled("div")(() => ({
    display: "flex",
    gap: "20px",
    alignItems: "center",
    img: {
      cursor: "pointer",
    },
  })),
  Search: styled("div")(() => ({
    width: "100%",
    maxWidth: "322px",
  })),
  DropdownMenu: styled("div")({
    position: "absolute",
    top: "100%",
    right: "20px",
    backgroundColor: "white",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.15)",
    zIndex: 1,
    padding: "8px",
    borderRadius: "12px",
    width: "120px",
  }),
  MenuItem: styled("div")(() => ({
    padding: "6px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: colorToken.primary_main_5dp,
    },
  })),
};

export default Header;
