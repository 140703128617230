import React, { useState } from "react";
import Input from "_components/Input/Input";
import Typography from "_components/Typography";
import styled from "styled-components";

const SetupTwo = (props) => {
  const { watch, setValue } = props;

  const name = watch("name");
  const companyName = watch("companyName");

  const handleCompanyNameOnChange = (e) => {
    setValue("companyName", e.target.value);
  };

  const handleNameOnChange = (e) => {
    setValue("name", e.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Input
        tabIndex={-1}
        label={
          <Typography variant="caption.100" color="text_20">
            이름
          </Typography>
        }
        placeholder={"이름을 입력해주세요."}
        value={name}
        onChange={handleNameOnChange}
      />

      <Input
        tabIndex={-1}
        label={
          <Typography variant="caption.100" color="text_20">
            회사명
          </Typography>
        }
        value={companyName}
        placeholder={"회사명을 입력해주세요."}
        onChange={handleCompanyNameOnChange}
      />
    </div>
  );
};

export default SetupTwo;

const S = {
  Container: styled("div")<any>(() => ({
    padding: "48px ",
    background: "white",
    borderRadius: "12px",
  })),
  Title: styled("div")<any>(() => ({
    marginBottom: "28px",
  })),
  SocialLogin: styled("div")<any>(() => ({
    width: "380px",
    padding: "9px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    border: "1px solid var(--btn-btn2-N, rgba(0, 0, 0, 0.05))",
    borderRadius: "4px",
    cursor: "pointer",
  })),
  SocialLoginContainer: styled("div")<any>(() => ({
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  })),
  Line: styled("div")<any>(() => ({
    flex: 1,
    background: "var(--line-10, #F2F3F5)",
    height: "1px",
  })),
  LineContainer: styled("div")<any>(() => ({
    display: "flex",
    gap: "16px",
    alignItems: "center",
    padding: "16px",
  })),
  ButtonContainer: styled("div")<any>(() => ({
    marginTop: "34px",
    marginBottom: "12px",
  })),
};
