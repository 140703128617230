import React, { useState } from "react";
import { Button } from "_components/Button";
import Typography from "_components/Typography";
import useLoginSignUp from "_hooks/use-login-sign-up";
import { useModal } from "_hooks/use-modal";
import { icons } from "_styles/shared/icons";
import Image from "next/image";
import styled from "styled-components";

import { terms } from "../termsData";

import { TermsCheckboxes } from "./TermsCheckboxes";

const Agree = () => {
  const { handleNextStep, handlePreviousStep } = useLoginSignUp();

  const [agreed, setAgreed] = useState(new Array(terms.length).fill(false));
  const { open, close } = useModal();

  const openTermModal = (index) => {
    open({
      type: "termsAgreement",
      currentTerm: terms[index],
      handleAgree: () => {
        setAgreed((prev) => {
          const newAgreed = [...prev];
          newAgreed[index] = true;
          return newAgreed;
        });
        close();
      },
      isLastTerm: true,
    });
  };

  const handleToggleAgree = (index) => {
    if (agreed[index]) {
      setAgreed((prev) => {
        const newAgreed = [...prev];
        newAgreed[index] = false;
        return newAgreed;
      });
    } else {
      openTermModal(index);
    }
  };

  const handleAllAgree = () => {
    const allAgreed = agreed.every(Boolean);
    if (allAgreed) {
      setAgreed(new Array(terms.length).fill(false));
    } else {
      const uncheckedTerms = terms
        .map((term, index) => ({ ...term, index }))
        .filter((term) => !agreed[term.index]);

      if (uncheckedTerms.length > 0) {
        let currentIndex = 0;
        const openNextModal = () => {
          if (currentIndex < uncheckedTerms.length) {
            open({
              type: "termsAgreement",
              currentTerm: terms[uncheckedTerms[currentIndex].index],
              handleAgree: () => {
                setAgreed((prev) => {
                  const newAgreed = [...prev];
                  newAgreed[uncheckedTerms[currentIndex - 1]?.index] = true;
                  return newAgreed;
                });
                close();
                currentIndex++;
                openNextModal();
              },
              isLastTerm: currentIndex === uncheckedTerms.length - 1,
            });
          }
        };
        openNextModal();
      } else {
        setAgreed(new Array(terms.length).fill(true));
      }
    }
  };

  return (
    <S.Container>
      <S.Title>
        <Typography variant="title.200_sb" color={"text_5"}>
          약관에 동의해주세요.
        </Typography>
        <Typography variant="body.100" color={"text_5"}>
          서비스 이용을 위해 아래 약관의 동의가 필요합니다.
        </Typography>
      </S.Title>
      <TermsCheckboxes
        agreed={agreed}
        handleAllAgree={handleAllAgree}
        handleToggleAgree={handleToggleAgree}
      />
      <S.ButtonContainer>
        <Button
          startIcon={
            <Image
              src={icons.chevron.left.bgray200_16}
              width={16}
              height={16}
              alt=""
            />
          }
          variant="label"
          size="md"
          onClick={handlePreviousStep}
        >
          <Typography variant="body.201" color={"text_10"}>
            이전
          </Typography>
        </Button>
        <Button
          disabled={!agreed.filter((_, i) => terms[i].required).every(Boolean)}
          onClick={handleNextStep}
          variant="default"
          size="md"
          endIcon={
            <Image
              src={icons.chevron.right.bgray200_16}
              width={16}
              height={16}
              alt=""
            />
          }
        >
          <Typography variant="body.201">다음</Typography>
        </Button>
      </S.ButtonContainer>
    </S.Container>
  );
};

export default Agree;

const S = {
  Container: styled.div`
    width: 440px;
  `,
  Title: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 20px;
  `,
  ButtonContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  `,
};
