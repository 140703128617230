import { useState } from "react";
import { Button } from "_components/Button";
import Input from "_components/Input/Input";
import Typography from "_components/Typography";
import useLoginSignUp from "_hooks/use-login-sign-up";
import { findOneByEmail } from "_libs/fetcher/user";
import styled from "styled-components";

const SignEmail = (props) => {
  const { handleNextStep, setTab } = useLoginSignUp();

  const { email, setEmail } = props;

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const SignEmail = async () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailRegex.test(email)) {
      setError(true);
      setErrorMessage("이메일 형식이 올바르지 않습니다.");
      return;
    }

    const result = await findOneByEmail(email);

    console.log(result);

    if (result === true) {
      setError(true);
      setErrorMessage("이미 존재하는 계정입니다.");
      return;
    }

    setEmail(email);

    handleNextStep();
  };

  return (
    <>
      <S.Title>
        <Typography variant="title.200_sb" color={"text_5"}>
          회원가입을 진행합니다.
        </Typography>
      </S.Title>
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Input
          placeholder="이메일 주소를 입력해주세요."
          value={email}
          onChange={handleOnChange}
          error={error}
          errorMessage={errorMessage}
        />

        <Button
          variant="default"
          size="md"
          width="100%"
          disabled={email === ""}
          onClick={SignEmail}
        >
          <Typography
            variant="body.200"
            color={email === "" ? "text_40" : "text_60"}
          >
            이메일로 가입하기
          </Typography>
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "8px",
          marginTop: "12px",
        }}
      >
        <Typography variant="body.200" color="text_15">
          이미 계정이 있으신가요?
        </Typography>
        <Button variant="label" size="md" onClick={() => setTab("login")}>
          <Typography variant="body.201" color="blue_900">
            로그인
          </Typography>
        </Button>
      </div>
    </>
  );
};

export default SignEmail;

const S = {
  Container: styled("div")<any>(() => ({
    padding: "48px",
    width: "470px",
    background: "white",
    borderRadius: "12px",
    overflow: "hidden",
    boxSizing: "border-box",
  })),

  Title: styled("div")<any>(() => ({
    marginBottom: "28px",
  })),
  SocialLogin: styled("div")<any>(() => ({
    width: "380px",
    padding: "9px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    border: "1px solid var(--btn-btn2-N, rgba(0, 0, 0, 0.05))",
    borderRadius: "4px",
    cursor: "pointer",
  })),
  SocialLoginContainer: styled("div")<any>(() => ({
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  })),
  Line: styled("div")<any>(() => ({
    flex: 1,
    background: "var(--line-10, #F2F3F5)",
    height: "1px",
  })),
  LineContainer: styled("div")<any>(() => ({
    display: "flex",
    gap: "16px",
    alignItems: "center",
    padding: "16px",
  })),
  ButtonContainer: styled("div")<any>(() => ({
    marginTop: "16px",
    marginBottom: "12px",
  })),
};
