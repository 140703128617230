import React from "react";
import { Checkbox } from "_components/Checkbox";
import Typography from "_components/Typography";
import styled from "styled-components";

import { terms } from "../termsData";

import { AgreeContent } from "./AgreeContent";

interface TermsCheckboxesProps {
  agreed: boolean[];
  handleAllAgree: () => void;
  handleToggleAgree: (index: number) => void;
}

export const TermsCheckboxes: React.FC<TermsCheckboxesProps> = ({
  agreed,
  handleAllAgree,
  handleToggleAgree,
}) => {
  return (
    <S.Container>
      <S.AllAgreeBox>
        <S.AllAgreeContainer onClick={handleAllAgree}>
          <Checkbox checked={agreed.every(Boolean)} onChange={handleAllAgree} />
          <Typography variant="body.100_sb" color={"text_5"}>
            모두 동의합니다.
          </Typography>
        </S.AllAgreeContainer>
      </S.AllAgreeBox>
      <S.TermsBox>
        {terms.map((term, index) => (
          <AgreeContent
            key={term.id}
            type={term.required ? "required" : "optional"}
            text={term.name}
            checked={agreed[index]}
            onChange={() => handleToggleAgree(index)}
            onView={() => handleToggleAgree(index)} // handleOpen 대신 handleToggleAgree 사용
          />
        ))}
      </S.TermsBox>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    width: 100%;
  `,
  AllAgreeBox: styled.div`
    background-color: var(--background_default);
    padding: 16px 28px;
    display: flex;
    gap: 8px;
    align-items: center;
  `,
  TermsBox: styled.div`
    padding: 20px 28px;
    display: flex;
    flex-direction: column;
    gap: 13px;
  `,
  AllAgreeContainer: styled.div`
    display: inline-flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  `,
};
