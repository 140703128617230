import { useState } from "react";
import Input from "_components/Input/Input";
import Typography from "_components/Typography";
import useSnackBar from "_hooks/SnackBar/useSnackBar";
import { illust } from "_styles/shared/icons";
import Image from "next/image";
import styled from "styled-components";

const Login = (props: any) => {
  const { open } = useSnackBar();
  const { watch, setValue } = props;

  const email = watch("email");

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue("email", e.target.value);
  };

  const handleSocialLogin = (platform: string) => {
    open({
      variant: "info",
      text: "서비스 준비중입니다.",
    });
  };

  return (
    <div>
      <S.SocialLoginContainer>
        <S.SocialLogin onClick={() => handleSocialLogin("google")}>
          <Image alt="Google" src={illust.google.logo} width={20} height={20} />
          <Typography variant="body.200" color={"text_10"}>
            Google로 계속하기
          </Typography>
        </S.SocialLogin>

        <S.SocialLogin onClick={() => handleSocialLogin("apple")}>
          <Image alt="Apple" src={illust.apple} width={20} height={20} />
          <Typography variant="body.200" color={"text_10"}>
            Apple로 계속하기
          </Typography>
        </S.SocialLogin>
      </S.SocialLoginContainer>

      <S.LineContainer>
        <S.Line />

        <Typography variant="body.200" color={"text_15"}>
          또는
        </Typography>

        <S.Line />
      </S.LineContainer>

      <Input
        value={email}
        onChange={handleOnChange}
        placeholder={"이메일 주소를 입력해주세요."}
      />
    </div>
  );
};
export default Login;

const S = {
  Container: styled("div")<any>(() => ({
    padding: "48px ",
    background: "white",
    borderRadius: "12px",
  })),
  Title: styled("div")<any>(() => ({
    marginBottom: "28px",
  })),
  SocialLogin: styled("div")<any>(() => ({
    width: "384px",
    boxSizing: "border-box",
    padding: "9px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    border: "1px solid var(--btn-btn2-N, rgba(0, 0, 0, 0.05))",
    borderRadius: "4px",
    cursor: "pointer",
  })),
  SocialLoginContainer: styled("div")<any>(() => ({
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  })),
  Line: styled("div")<any>(() => ({
    flex: 1,
    background: "var(--line-10, #F2F3F5)",
    height: "1px",
  })),
  LineContainer: styled("div")<any>(() => ({
    display: "flex",
    gap: "16px",
    alignItems: "center",
    padding: "16px",
  })),
  ButtonContainer: styled("div")<any>(() => ({
    marginTop: "16px",
    marginBottom: "12px",
  })),
};
