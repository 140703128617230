import { useEffect, useState } from "react";
import useLoginSignUp from "_hooks/use-login-sign-up";
import { findOneByEmail } from "_libs/fetcher/user";
import { getPasswordToken } from "_libs/swr/auth/auth";
import { useSearchParams } from "next/navigation";
import styled from "styled-components";

import Agree from "./Agree";
import EmailPost from "./EmailPost";
import EmailSignUp from "./EmailSignUp";
import EmailVerification from "./EmailVerification";

const signStepObj = [
  {
    page: EmailSignUp,
  },
  { page: Agree },
  { page: EmailVerification },
  { page: EmailPost },
];

const SignUpStep = () => {
  const { currentStep, setTab, handlePasswordStep } = useLoginSignUp();

  const [email, setEmail] = useState("");

  const Component = signStepObj[currentStep].page;

  const searchParams = useSearchParams();
  const getToken = searchParams.get("token");

  const { data, isLoading } = getPasswordToken(getToken);

  useEffect(() => {
    if (!isLoading && data) {
      const checkEmailAndSetStep = async () => {
        const result = await findOneByEmail(data.email);

        if (!result) {
          handlePasswordStep();
          return;
        } else {
          setTab("login");
          return;
        }
      };

      if (getToken) return;
      else {
        checkEmailAndSetStep();
      }
    }
  }, [isLoading]);

  return (
    <S.Container>
      <Component email={email} setEmail={setEmail} />
    </S.Container>
  );
};

export default SignUpStep;

const S = {
  Container: styled("div")<any>(() => ({
    padding: "48px",
    minWidth: "470px",
    maxWidth: "537px",
    background: "white",
    borderRadius: "12px",
    overflow: "hidden",
    boxSizing: "border-box",
  })),
};
