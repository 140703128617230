import floating from "_assets/icons/ic_kura_logo_32.png";
import Image from "next/image";
import styled from "styled-components";

const Toggle = ({ onClick }: { onClick: () => void }) => (
  <S.KuraToggle
    onClick={onClick}
    src={floating}
    width={32}
    height={32}
    alt="chatbot"
    draggable={false}
  />
);

export default Toggle;

const S = {
  KuraToggle: styled(Image)({
    position: "fixed",
    bottom: "20px",
    right: "20px",
    zIndex: 1000,
    cursor: "pointer",
    backgroundColor: "var(--white)",
    padding: "14px",
    borderRadius: "50%",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
  }),
};
