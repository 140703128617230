import { useState } from "react";
import { Button } from "_components/Button";
import Input from "_components/Input/Input";
import Typography from "_components/Typography";
import useSnackBar from "_hooks/SnackBar/useSnackBar";
import useLoginSignUp from "_hooks/use-login-sign-up";
import { sendEmailToUser } from "_libs/fetcher/auth";
import styled from "styled-components";

const EmailVerification = (props) => {
  const { email } = props;
  const { handleNextStep, setTab } = useLoginSignUp();

  const { open } = useSnackBar();

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const SendEmail = async () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailRegex.test(email)) {
      setError(true);
      setErrorMessage("이메일 형식이 올바르지 않습니다.");
      return;
    }

    const result = await sendEmailToUser({ email: email, type: "setting" });

    if (result && result?.isSent === true) handleNextStep();
    else
      return open({ text: "인증 메일 발송에 실패했습니다.", variant: "error" });

    console.log(result);
  };
  return (
    <>
      <S.Title>
        <Typography variant="title.200_sb" color={"text_5"}>
          이메일 인증이 필요합니다.
        </Typography>
      </S.Title>
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Input
          placeholder="이메일 주소를 입력해주세요."
          value={email}
          error={error}
          errorMessage={errorMessage}
          disabled
          inputWrapperStyle={{ opacity: 1 }}
          endAdornment={
            <Button size="xs" variant="default" onClick={SendEmail}>
              <Typography variant="caption.100" color="white">
                이메일 인증하기
              </Typography>
            </Button>
          }
        />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "12px",
        }}
      >
        <Button variant="label" size="md">
          <Typography variant="body.201" color="blue_900">
            인증메일을 받지 못하셨나요?
          </Typography>
        </Button>
      </div>
    </>
  );
};

export default EmailVerification;

const S = {
  Container: styled("div")<any>(() => ({
    padding: "48px",
    width: "470px",
    background: "white",
    borderRadius: "12px",
    overflow: "hidden",
    boxSizing: "border-box",
  })),

  Title: styled("div")<any>(() => ({
    marginBottom: "28px",
  })),
  SocialLogin: styled("div")<any>(() => ({
    width: "380px",
    padding: "9px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    border: "1px solid var(--btn-btn2-N, rgba(0, 0, 0, 0.05))",
    borderRadius: "4px",
    cursor: "pointer",
  })),
  SocialLoginContainer: styled("div")<any>(() => ({
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  })),
  Line: styled("div")<any>(() => ({
    flex: 1,
    background: "var(--line-10, #F2F3F5)",
    height: "1px",
  })),
  LineContainer: styled("div")<any>(() => ({
    display: "flex",
    gap: "16px",
    alignItems: "center",
    padding: "16px",
  })),
  ButtonContainer: styled("div")<any>(() => ({
    marginTop: "16px",
    marginBottom: "12px",
  })),
};
