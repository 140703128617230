import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button } from "_components/Button";
import Typography from "_components/Typography";
import useSnackBar from "_hooks/SnackBar/useSnackBar";
import useLoginSignUp from "_hooks/use-login-sign-up";
import { updatePassword } from "_libs/fetcher/user";
import { getPasswordToken } from "_libs/swr/auth/auth";
import { isPasswordValid } from "_utils/password";
import { useSearchParams } from "next/navigation";
import styled from "styled-components";

import SetupOne from "../Setup/SetupOne";

const initData = {
  email: "",
  password: "",
  passwordConfirm: "",
};

const Reset = () => {
  const { open } = useSnackBar();

  const { handleNextStep, setTab } = useLoginSignUp();

  const { watch, setValue, handleSubmit, reset } = useForm({
    defaultValues: initData,
  });

  const searchParams = useSearchParams();

  const getToken = searchParams.get("token");

  const { data } = getPasswordToken(getToken);

  useEffect(() => {
    if (data) {
      setValue("email", data.email);
    }
  }, [data]);

  const handlePasswordReset = async (props) => {
    const { email, password, passwordConfirm } = props;
    if (!email) {
      open({
        variant: "error",
        text: "이메일 인증을 다시 시도해주세요.",
      });
      return setTab("login");
    }

    if (!isPasswordValid(password) || !isPasswordValid(passwordConfirm)) {
      return open({
        variant: "error",
        text: "비밀번호 필수 조건이 적합하지 않습니다.",
      });
    }

    if (password !== passwordConfirm) {
      return open({
        variant: "error",
        text: "비밀번호가 일치하지 않습니다.",
      });
    }

    const res = await updatePassword({ email: email, password: password });

    if (res) {
      handleNextStep();
    }
  };

  return (
    <form onSubmit={handleSubmit(handlePasswordReset)}>
      <S.Title>
        <Typography variant="title.200_sb" color="text_5">
          비밀번호 재설정
        </Typography>
      </S.Title>
      <SetupOne watch={watch} setValue={setValue} />

      <S.ButtonContainer>
        <Button type="submit" variant="default" size="md" width="100%">
          <Typography variant="body.201">변경하기</Typography>
        </Button>
      </S.ButtonContainer>
    </form>
  );
};

export default Reset;
const S = {
  Title: styled("div")<any>(() => ({
    display: "flex",
    flexDirection: "column",
    marginBottom: "28px",
  })),
  SocialLogin: styled("div")<any>(() => ({
    width: "380px",
    padding: "9px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    border: "1px solid var(--btn-btn2-N, rgba(0, 0, 0, 0.05))",
    borderRadius: "4px",
    cursor: "pointer",
  })),
  SocialLoginContainer: styled("div")<any>(() => ({
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  })),
  Line: styled("div")<any>(() => ({
    flex: 1,
    background: "var(--line-10, #F2F3F5)",
    height: "1px",
  })),
  LineContainer: styled("div")<any>(() => ({
    display: "flex",
    gap: "16px",
    alignItems: "center",
    padding: "16px",
  })),
  ButtonContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 36px;
  `,
};
