"use client";
import React from "react";
import Ai from "_assets/icons/ic_ai_on_white_16.svg";
import Billing from "_assets/icons/ic_billing_on_white_16.svg";
import Calender from "_assets/icons/ic_calendar_on_white_16.svg";
import Consulting from "_assets/icons/ic_consulting_on_white_16.svg";
import Dashboard from "_assets/icons/ic_dashboard_on_white_16.svg";
import DataLabeling from "_assets/icons/ic_labeling_on_white_16.svg";
import Management from "_assets/icons/ic_management_on_white_16.svg";
import SmartMatching from "_assets/icons/ic_matching_on_white_16.svg";
import Monitoring from "_assets/icons/ic_monitering_on_white_16.svg";
import Storage from "_assets/icons/ic_storage_on_white_16.svg";
import Link from "next/link";
import styled from "styled-components";

import MenuItem from "./MenuItem";
import { IMenuCategory } from "./types";

const categories: IMenuCategory[] = [
  {
    key: "dashboard",
    label: "Dashboard",
    href: "/",
    icon: {
      on: Dashboard,
      off: Dashboard,
    },
  },
  {
    key: "storage",
    label: "Storage",
    href: "/storage",
    icon: {
      on: Storage,
      off: Storage,
    },
  },
  {
    key: "monitoring",
    label: "Monitoring",
    href: "/monitoring",
    icon: {
      on: Monitoring,
      off: Monitoring,
    },
  },
  {
    key: "data-labeling",
    label: "Data Labeling",
    href: "/data-labeling",
    icon: {
      on: DataLabeling,
      off: DataLabeling,
    },
  },
  {
    key: "ai",
    label: "Ai",
    href: "/ai",
    icon: {
      on: Ai,
      off: Ai,
    },
  },
  {
    type: "group",
    key: "smart-matching",
    label: "Smart Matching",
    href: "/smart-matching",
    icon: {
      on: SmartMatching,
      off: SmartMatching,
    },
    children: [
      {
        key: "status",
        href: "/smart-matching/status",
        label: "매칭 현황",
      },
      {
        key: "plwida",
        href: "/smart-matching/plwida",
        label: "PlWiDa 매칭",
      },
      {
        key: "high-five",
        href: "/smart-matching/high-five",
        label: "하이파이브",
      },
    ],
  },
  {
    key: "management",
    label: "Management",
    href: "/management",
    icon: {
      on: Management,
      off: Management,
    },
  },
  {
    key: "consulting",
    label: "Consulting",
    href: "/consulting",
    icon: {
      on: Consulting,
      off: Consulting,
    },
  },
  {
    key: "calendar",
    label: "Calendar",
    href: "/calendar",
    icon: {
      on: Calender,
      off: Calender,
    },
  },
  {
    key: "billing",
    label: "Billing",
    href: "/billing",
    icon: {
      on: Billing,
      off: Billing,
    },
  },
];

const SNB = () => {
  return (
    <S.SNB>
      <S.Title href="/">
        <span />
      </S.Title>

      <S.Menu>
        {categories.map((category: IMenuCategory) => (
          <MenuItem key={category.key} category={category} />
        ))}
      </S.Menu>
    </S.SNB>
  );
};

const S = {
  SNB: styled.div`
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    width: 180px;
    height: 100vh;
    background-color: #2c2c2c;

    @media (max-width: 1024px) {
      width: 64px;
    }
  `,
  Title: styled(Link)(() => ({
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
    cursor: "default",
    textDecoration: "none",
    "@media (max-width: 1024px)": {
      justifyContent: "center",
    },

    span: {
      color: "var(--line_40)",
      fontSize: "32px",
      fontWeight: 900,
      cursor: "pointer",
      "&:before": {
        content: '"PlWida"',
        "@media (max-width: 1024px)": {
          content: '"P"',
        },
      },
    },
  })),
  Menu: styled.div`
    flex-grow: 1;
  `,
};

SNB.displayName = "SNB";

export default SNB;
