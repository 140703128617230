/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import Send from "_assets/icons/ic_send_default_16.svg";
import Input from "_components/Input/Input";
import Image from "next/image";
import styled from "styled-components";

const KuraInput = ({
  onSendMessage,
}: {
  onSendMessage: (message: string) => void;
}) => {
  const [inputMessage, setInputMessage] = useState("");

  const handleSend = () => {
    if (inputMessage.trim()) {
      onSendMessage(inputMessage);
      setInputMessage("");
    }
  };

  return (
    <S.ChatbotInputContainer>
      <Input
        type="text"
        value={inputMessage}
        onChange={(e) => setInputMessage(e.target.value)}
        placeholder="어떤 서비스가 필요한가요?"
        onKeyPress={(e) => e.key === "Enter" && handleSend()}
        style={{
          width: "100%",
        }}
        endAdornment={
          <Send alt="send" onClick={handleSend} style={{ cursor: "pointer" }} />
        }
      />
    </S.ChatbotInputContainer>
  );
};

export default KuraInput;

const S = {
  ChatbotInputContainer: styled("div")({
    display: "flex",
    paddingTop: "20px",
  }),
};
