/* eslint-disable turbo/no-undeclared-env-vars */
import React, { useCallback, useEffect, useState } from "react";
import useEventSource from "_hooks/useEventSource";

import KuraClient from ".";

interface Message {
  id: number;
  type: "question" | "answer" | "notification";
  content: string;
}

const mockNotification: any = [
  {
    id: 2,
    type: "notification",
    content: `클라우드 운영과 분석 리소스를 절감하려면 'AWS'에서 'Oracle'로 변경하는 건 어떠세요?

변경 시, 지난달 대비 운영비를 24% 절감하고, 보안이 강화되어 안정성은 40% 향상돼요! 

정량적인 비용 절감 효과는 약 365만 원입니다

지금 변경해 보시겠어요?`,
  },
  {
    id: 1,
    type: "notification",
    content: `지난달부터 텍스트 데이터가 25.5% 증가했습니다. 📈

AWS 스토리지를 이용하시면 운영 비용을 최대 20%까지 절감할 수 있습니다. 

지금 변경해 보시겠어요?`,
  },
];

const KuraApp: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const { connect, disconnect } = useEventSource();

  const toggleChatbot = () => setIsOpen(!isOpen);

  useEffect(() => {
    // 초기 메시지 설정 (AI 뷰가 열릴 때만 실행)
    if (messages.length === 0) {
      setMessages(mockNotification);
    }

    // AI 뷰가 닫힐 때 연결 해제
    if (!isOpen) {
      disconnect();
    }
  }, [isOpen, disconnect, messages.length]);

  const handleSendMessage = useCallback(
    (message: string) => {
      const newMessage: Message = {
        id: Date.now(),
        type: "question",
        content: message,
      };

      setMessages((prevMessages) => [newMessage, ...prevMessages]);

      let fullResponse = "";

      // gpt-4, gpt-4o, gpt-3.5-turbo, gpt-4o-mini
      connect({
        url: `${process.env.NEXT_PUBLIC_API_URL}ai/chat-gpt?message=${encodeURIComponent(message)}&model=gpt-4o&type=gpt`,
        onMessage: (data) => {
          fullResponse += data;
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            const answerIndex = updatedMessages.findIndex(
              (msg) => msg.type === "answer" && msg.id === newMessage.id + 1,
            );

            if (answerIndex !== -1) {
              updatedMessages[answerIndex] = {
                ...updatedMessages[answerIndex],
                content: fullResponse,
              };
            } else {
              updatedMessages.unshift({
                id: newMessage.id + 1,
                type: "answer",
                content: fullResponse,
              });
            }

            return updatedMessages;
          });
        },
        onError: (error) => {
          console.error("EventSource failed:", error);
        },
      });
    },
    [connect],
  );

  return (
    <>
      {isOpen && (
        <KuraClient>
          <KuraClient.Header />
          <KuraClient.Content messages={messages} />
          <KuraClient.Input onSendMessage={handleSendMessage} />
        </KuraClient>
      )}
      <KuraClient.Toggle onClick={toggleChatbot} />
    </>
  );
};

export default KuraApp;
