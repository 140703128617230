import { apiInstance } from "_utils/axiosInstance";

export async function sendEmailToUser(authenticateUserReqDto: {
  email: string;
  type: "setting" | "reset";
}) {
  try {
    const { data } = await apiInstance.post(
      "auth/email",
      authenticateUserReqDto,
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function findOneWithPassword(loginUserReqDto: any) {
  try {
    const { data } = await apiInstance.post("auth/login", loginUserReqDto);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function verifyResetPasswordToken(token: string) {
  try {
    const { data } = await apiInstance.get(
      `auth/verify-reset-token?token=${token}`,
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function logout() {
  try {
    const { data } = await apiInstance.post("/auth/logout");
    return data;
  } catch (error) {
    return error;
  }
}
