"use client";
import { Grid } from "_components/Grid";
import Header from "_components/Header";
import KuraApp from "_components/KuraClient/KuraApp";
import Loading from "_components/Loading";
import LoginSignUp from "_components/LoginSignUp";
import { ModalProvider } from "_components/Modal/ModalProvider";
import SnackBarProvider from "_components/SnackBar/SnackBarProvider";
import SNB from "_components/SNB";
import { useLayoutRedirect } from "_hooks/useLayoutRedirect";
import StyledComponentsRegistry from "_libs/provider/StyledComponentsRegistry";
import { getMyUser } from "_libs/swr/user/user";
import { usePathname } from "next/navigation";
import styled from "styled-components";

import ThemeContextProvider from "./_libs/provider/themeProvider";

import "./_styles/globalStyles.css";

const InnerLayout = ({ children }) => {
  const { data: userData, mutate, isLoading, isError }: any = getMyUser();

  const pathname = usePathname();

  // useLayoutRedirect(userData);

  // SNB가 보이지 않아야 하는 경로들
  const pathsWithoutSNB = ["/external-connection", "/other-full-width-path"];
  const shouldHideSNB = pathsWithoutSNB.some(
    (path) =>
      pathname.includes(path) &&
      pathname !== "/external-connection/aws/storage/",
  );

  // KuraApp가 보이지 않아야 하는 경로들
  const pathsWithoutKuraApp = ["/ai", "/external-connection"];
  const shouldHideKuraApp = pathsWithoutKuraApp.some((path) =>
    pathname.includes(path),
  );

  if (isLoading) return <Loading.Plwida />;
  if (!userData) return <LoginSignUp />;

  return (
    <S.Container>
      {!shouldHideSNB && <SNB />}

      <S.Flex>
        <Grid.Container style={{ position: "relative", zIndex: 1 }}>
          <S.FixedHeader xl={12} lg={12} md={8} shouldHideSNB={shouldHideSNB}>
            <Header />
          </S.FixedHeader>
        </Grid.Container>

        <S.FixedContents shouldHideSNB={shouldHideSNB}>
          {children}
        </S.FixedContents>

        {userData && !shouldHideKuraApp && <KuraApp />}
      </S.Flex>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    display: flex;
    /* max-width: 1920px; */
    position: relative;
  `,
  Flex: styled.div`
    width: 100%;
  `,
  FixedHeader: styled(Grid.Item)<{ shouldHideSNB: boolean }>`
    position: fixed;
    top: 0;
    width: ${({ shouldHideSNB }) =>
      shouldHideSNB ? "100%" : "calc(100% - 180px)"};
    margin-left: ${({ shouldHideSNB }) => (shouldHideSNB ? "0" : "180px")};
    @media (max-width: 1024px) {
      width: ${({ shouldHideSNB }) =>
        shouldHideSNB ? "100%" : "calc(100% - 64px)"};
      margin-left: ${({ shouldHideSNB }) => (shouldHideSNB ? "0" : "65px")};
    }
  `,
  FixedContents: styled.div<{ shouldHideSNB: boolean }>`
    position: relative;
    z-index: 0;
    margin-top: 69px;
    margin-left: ${({ shouldHideSNB }) => (shouldHideSNB ? "0" : "180px")};
    height: calc(100vh - 70px);
    @media (max-width: 1024px) {
      margin-left: ${({ shouldHideSNB }) => (shouldHideSNB ? "0" : "65px")};
    }
  `,
};

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <html lang="ko">
      <body>
        <StyledComponentsRegistry>
          <ThemeContextProvider>
            <ModalProvider>
              <SnackBarProvider>
                <InnerLayout>{children}</InnerLayout>
              </SnackBarProvider>
            </ModalProvider>
          </ThemeContextProvider>
        </StyledComponentsRegistry>
      </body>
    </html>
  );
}
