import React, { useState } from "react";
import Input from "_components/Input/Input";
import Typography from "_components/Typography";
import { icons } from "_styles/shared/icons";
import Image from "next/image";
import styled from "styled-components";

const Password = (props: any) => {
  const { watch, setValue } = props;

  const email = watch("email");
  const password = watch("password");

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleOnChange = (e) => {
    setValue("password", e.target.value);
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "34px" }}>
        <Input
          label={
            <Typography variant="caption.100" color="text_20">
              이메일
            </Typography>
          }
          tabIndex={-1}
          value={email}
          placeholder={"인증번호를 입력해주세요."}
          inputWrapperStyle={{ opacity: 1 }}
          disabled
        />
        <Input
          type={isPasswordVisible ? "text" : "password"}
          label={
            <Typography variant="caption.100" color="text_20">
              비밀번호
            </Typography>
          }
          value={password}
          tabIndex={-1}
          placeholder={"비밀번호를 입력해주세요."}
          onChange={handleOnChange}
          endAdornment={
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsPasswordVisible(!isPasswordVisible);
              }}
            >
              {isPasswordVisible ? (
                <Image src={icons.eye.on} alt="eye" width={20} height={20} />
              ) : (
                <Image src={icons.eye.off} alt="eye" width={20} height={20} />
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Password;

const S = {
  Container: styled("div")<any>(() => ({
    padding: "48px ",
    background: "white",
    borderRadius: "12px",
  })),
  Title: styled("div")<any>(() => ({
    marginBottom: "28px",
  })),
  SocialLogin: styled("div")<any>(() => ({
    width: "380px",
    padding: "9px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    border: "1px solid var(--btn-btn2-N, rgba(0, 0, 0, 0.05))",
    borderRadius: "4px",
    cursor: "pointer",
  })),
  SocialLoginContainer: styled("div")<any>(() => ({
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  })),
  Line: styled("div")<any>(() => ({
    flex: 1,
    background: "var(--line-10, #F2F3F5)",
    height: "1px",
  })),
  LineContainer: styled("div")<any>(() => ({
    display: "flex",
    gap: "16px",
    alignItems: "center",
    padding: "16px",
  })),
  ButtonContainer: styled("div")<any>(() => ({
    marginTop: "34px",
    marginBottom: "12px",
  })),
};
