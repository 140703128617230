import React, { useEffect, useRef, useState } from "react";
import { set, useForm } from "react-hook-form";
import Slider from "react-slick";
import { Button } from "_components/Button";
import Typography from "_components/Typography";
import useSnackBar from "_hooks/SnackBar/useSnackBar";
import useLoginSignUp from "_hooks/use-login-sign-up";
import { findOneWithPassword, sendEmailToUser } from "_libs/fetcher/auth";
import { findOneByEmail } from "_libs/fetcher/user";
import { getMyUser } from "_libs/swr/user/user";
import { useRouter } from "next/navigation";
import styled from "styled-components";

import { loginStepObj, LoginStepObject } from "./loginStepConfig";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const initData = {
  email: "",
  password: "",
};

const LoginStep = () => {
  const router = useRouter();
  const { mutate } = getMyUser();

  const { open } = useSnackBar();

  const { currentStep, handleNextStep, handlePreviousStep, setTab } =
    useLoginSignUp();

  const { handleSubmit, setValue, watch } = useForm({
    defaultValues: initData,
  });

  const handleLogin = async (props: any) => {
    try {
      const res = await findOneWithPassword(props);
      if (res) {
        mutate();
        open({
          variant: "success",
          text: "로그인에 성공하였습니다.",
        });

        location.href = "/external-connection";
      } else {
        open({
          variant: "error",
          text: "아이디 또는 비밀번호가 일치하지 않습니다.",
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const emailCheck = async () => {
    const email = watch("email");
    const result = await findOneByEmail(email);

    if (result) {
      return handleNextStep();
    } else {
      open({
        variant: "error",
        text: "해당 이메일로 가입된 계정을 찾을 수 없습니다.",
      });
      return;
    }
  };

  const sendEmail = async () => {
    const email = watch("email");
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailRegex.test(email)) {
      open({ text: "이메일 형식이 올바르지 않습니다.", variant: "error" });
      return;
    }

    const result = await sendEmailToUser({ email: email, type: "reset" });

    if (result && result?.isSent === true)
      open({ text: "인증 메일 발송이 완료 되었습니다.", variant: "success" });
    else
      return open({ text: "인증 메일 발송에 실패했습니다.", variant: "error" });

    console.log(result);
  };

  const sliderRef = useRef<Slider>(null);

  useEffect(() => {
    if (currentStep === 0) {
      setValue("email", "");
      setValue("password", "");
    }
    sliderRef.current?.slickGoTo(currentStep);
  }, [currentStep]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    fade: false,
  };

  const currentStepComponents: LoginStepObject = loginStepObj[currentStep];

  return (
    <S.Container>
      <form onSubmit={handleSubmit(handleLogin)}>
        <S.Title>
          <Typography variant="title.200_sb" color="text_5">
            {currentStepComponents.title}
          </Typography>
        </S.Title>
        <Slider ref={sliderRef} {...settings}>
          {loginStepObj.map((item: any, index) => (
            <div key={index}>
              {React.cloneElement(item.page, {
                watch,
                setValue,
                handlePreviousStep,
              })}
            </div>
          ))}
        </Slider>
        <S.ButtonContainer>
          {/* {currentStep === 2 && <div style={{ height: "28px" }}></div>} */}
          {currentStepComponents.button &&
            currentStepComponents.button({
              handleNextStep,
              emailCheck,
              sendEmail,
            })}
        </S.ButtonContainer>
        {currentStepComponents.subContents && (
          <S.SubContents>
            {currentStepComponents.subContents({ handleNextStep, setTab })}
          </S.SubContents>
        )}
      </form>
    </S.Container>
  );
};

const S = {
  Container: styled("div")<any>(() => ({
    padding: "48px",
    background: "white",
    borderRadius: "12px",
    width: "480px",
    height: "462px",
    overflow: "hidden",
    boxSizing: "border-box",
  })),
  Title: styled("div")<any>(() => ({
    marginBottom: "28px",
  })),
  ButtonContainer: styled("div")<any>(() => ({
    marginTop: "16px",
    marginBottom: "12px",
    height: "100%",
  })),
  SubContents: styled("div")<any>(() => ({
    display: "flex",
    alignItems: "center",
    marginLeft: "8px",
  })),
};

export default LoginStep;
