// 8자 이상 20자 이하 확인
export const isLengthValid = (password: string): boolean => {
  return password.length >= 8 && password.length <= 20;
};

// 특수문자 조합 사용 확인
export const hasSpecialChar = (password: string): boolean => {
  const specialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
  return specialChars.test(password);
};

// 숫자 조합 사용 확인
export const hasNumber = (password: string): boolean => {
  return /\d/.test(password);
};

// 모든 조건을 확인하는 함수
export const isPasswordValid = (password: string): boolean => {
  return (
    isLengthValid(password) && hasSpecialChar(password) && hasNumber(password)
  );
};
