import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import styled from "styled-components";

interface ReactMarkdownRendererProps {
  content: string;
}

const ReactMarkdownRenderer: React.FC<ReactMarkdownRendererProps> = ({
  content,
}) => {
  return (
    <StyledMarkdown>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw, rehypeSanitize, rehypeHighlight]}
      >
        {content}
      </ReactMarkdown>
    </StyledMarkdown>
  );
};

const StyledMarkdown = styled.div`
  font-size: 14px;
  line-height: 1.5;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    line-height: 1.25;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  h1 {
    font-size: 1.8em;
  }
  h2 {
    font-size: 1.4em;
  }
  h3 {
    font-size: 1.2em;
  }
  h4 {
    font-size: 1.1em;
  }
  h5 {
    font-size: 1em;
  }
  h6 {
    font-size: 0.9em;
  }

  p {
    margin-top: 0;
  }

  a {
    color: #0366d6;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  code {
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    background-color: rgba(27, 31, 35, 0.05);
    border-radius: 3px;
  }

  pre {
    padding: 12px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 3px;

    code {
      padding: 0;
      margin: 0;
      font-size: 100%;
      word-break: normal;
      white-space: pre;
      background: transparent;
      border: 0;
    }
  }

  ul,
  ol {
    list-style: auto;
    padding-left: 1.5em;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  li {
  }

  blockquote {
    padding: 0 1em;
    color: #6a737d;
    border-left: 0.25em solid #dfe2e5;
    margin: 0 0 12px 0;
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
    margin-bottom: 12px;
  }

  td,
  th {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
  }

  tr:nth-child(2n) {
    background-color: #f6f8fa;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

export default ReactMarkdownRenderer;
