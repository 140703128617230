import React from "react";
import { Button } from "_components/Button";
import { Checkbox } from "_components/Checkbox";
import Typography from "_components/Typography";
import styled from "styled-components";

interface AgreeContentProps {
  type: "required" | "optional";
  text: string;
  checked: boolean;
  onChange: () => void;
  onView: () => void;
}
export const AgreeContent = ({
  type,
  text,
  checked,
  onChange,
  onView,
}: AgreeContentProps) => {
  const typeObj: any = {
    required: {
      color: "primary_main",
      text: "[필수]",
    },
    optional: {
      color: "text_10",
      text: "[선택]",
    },
  };
  return (
    <S.Container>
      <S.ClickableDiv onClick={onView}>
        <Checkbox checked={checked} onChange={onChange} />
        <S.TextContainer>
          <Typography variant="body.300" color={typeObj[type].color}>
            {typeObj[type].text}
          </Typography>
          <Typography variant="body.100" color={"text_10"}>
            {text}
          </Typography>
        </S.TextContainer>
      </S.ClickableDiv>
      <S.ViewButtonContainer>
        <Button variant="grayline" size="2xs" onClick={onView}>
          보기
        </Button>
      </S.ViewButtonContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
  `,
  ClickableDiv: styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  TextContainer: styled.div`
    align-items: center;
    display: flex;
    gap: 4px;
  `,
  ViewButtonContainer: styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
  `,
};
