import { forwardRef } from "react";
import colorToken from "_styles/colorToken";
import typography from "_styles/typography";
import styled from "styled-components";

import { CheckboxProps } from "./types";

/**
 * @example
 * <Checkbox id='' label='' defaultChecked checked={false} onChange={() => {}} />
 */
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    id,
    label,
    defaultChecked,
    checked,
    onChange,
    checkColor,
    ...rootProps
  } = props;

  const isObjectLabel = typeof label === "object";

  const Label = () => {
    if (!label) return null;
    if (isObjectLabel) return label;
    return (
      <S.Label
        htmlFor={id}
        style={{
          cursor: "pointer",
          color: colorToken.text_10,
          ...typography["body.300"],
        }}
      >
        {label}
      </S.Label>
    );
  };

  return (
    <S.Root>
      <S.Checkbox
        type="checkbox"
        ref={ref}
        id={id}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        checkColor={checkColor}
      />

      <Label />
    </S.Root>
  );
});

Checkbox.displayName = "Checkbox";

const S = {
  Root: styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    gap: "8px",
  })),
  Checkbox: styled.input<{ checkColor?: string }>`
    margin: 0;
    width: 16px;
    height: 16px;
    /* accent-color: var(--primary_main); */
    cursor: pointer;
    outline: 1px solid var(--line_20);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 4px;

    :checked {
      background-color: ${({ checkColor }: any) =>
        checkColor || "var(--primary_main)"};
      outline: none;

      ::after {
        content: "\2713";
        color: white;
        padding: 4px;
      }
    }
  `,
  Label: styled("label")(() => ({
    // userSelect: "none",
  })),
};

export { Checkbox };
