import React, { useState } from "react";
import SVG from "_components/SVG/Svg";
import colorToken from "_styles/colorToken";
import { icons } from "_styles/shared/icons";
import typography from "_styles/typography";
import Image from "next/image";
import { usePathname, useRouter } from "next/navigation";
import styled from "styled-components";

import SubMenuItem from "./SubMenuItem";
import { MenuProps, StyledProps, SubMenuItemStyleProps } from "./types";

function getPath(pathname) {
  if (pathname === "/") return "home";
  return pathname.split("/")[1];
}

const MenuItem = ({ category }: MenuProps) => {
  const router = useRouter();
  const pathname = usePathname();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const path = getPath(pathname);
  const isSelected = path === category.key;

  const handleChangeMenu = (menu) => {
    setIsMenuOpen((prev) => !prev);
    onClickMenu(menu.href);
  };

  const onClickMenu = (href) => {
    router.push(href);
  };

  return (
    <>
      <S.Container onClick={() => handleChangeMenu(category)}>
        <S.MenuConatiner isSelected={isSelected}>
          <S.MenuTitleBox>
            {category.icon && (
              <SVG
                src={isSelected ? category.icon.on : category.icon.off}
                stroke={isSelected ? "var(--text_60)" : "var(--gray_500)"}
                width={16}
                height={16}
              />
            )}

            <S.Label isSelected={isSelected}>{category.label}</S.Label>
          </S.MenuTitleBox>

          {category.children && (
            <S.Arrow
              alt="arrow-icon"
              src={
                isMenuOpen
                  ? icons.chevron.up.bgray400_12
                  : icons.chevron.down.bgray400_12
              }
              width={16}
              height={16}
            />
          )}
        </S.MenuConatiner>

        <S.Divider isSelected={isSelected} />
      </S.Container>

      {/* subMenu */}
      {isMenuOpen &&
        category.children?.map((subCategory) => (
          <S.SubMenuItem key={subCategory.key} isMenuOpen={isMenuOpen}>
            <SubMenuItem category={subCategory} onClickMenu={onClickMenu} />
          </S.SubMenuItem>
        ))}
    </>
  );
};

const S = {
  Container: styled("div")(() => ({
    height: "48px",
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.07)",
    },
    textDecoration: "none",
  })),
  MenuConatiner: styled("div")(({ isSelected }: StyledProps) => ({
    gap: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "180px",
    padding: "8px 16px",
    backgroundColor: isSelected ? "rgba(255, 255, 255, 0.07)" : "",
  })),
  MenuTitleBox: styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: "100%",

    "@media (max-width: 1024px)": {
      justifyContent: "center",
    },
  })),
  Label: styled("span")(({ isSelected }: StyledProps) => ({
    ...typography["body.301"],
    color: isSelected ? colorToken.text_60 : colorToken.gray_500,
    "@media (max-width: 1024px)": {
      display: "none",
    },
  })),
  Divider: styled("div")(({ isSelected }: StyledProps) => ({
    width: "2px",
    backgroundColor: isSelected ? "var(--bg-snackbar, #363A47)" : "none",
  })),
  Arrow: styled(Image)(() => ({})),
  SubMenuItem: styled("div")(({ isMenuOpen }: SubMenuItemStyleProps) => ({
    cursor: "pointer",
    padding: "8px 16px",
    height: !isMenuOpen ? 0 : "auto",
    opacity: !isMenuOpen ? 0 : 1,
  })),
};

export default MenuItem;
