import Container from "./atoms/Container";
import Content from "./atoms/Content";
import Header from "./atoms/Header";
import Input from "./atoms/Input";
import Toggle from "./atoms/Toggle";

const KuraClient = Object.assign(Container, {
  Header,
  Content,
  Input,
  Toggle,
});

export default KuraClient;
