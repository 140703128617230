import { useEffect } from "react";
import useLoginSignUp from "_hooks/use-login-sign-up";
import { getPasswordToken } from "_libs/swr/auth/auth";
import { useSearchParams } from "next/navigation";
import styled from "styled-components";

import Reset from "./Reset";
import ResetComplete from "./ResetComplete";
import Setup from "./Setup";
import SetupComplete from "./SetupComplete";

const PasswordSettingPage = {
  reset: [<Reset />, <ResetComplete />],
  setting: [<Setup />, <SetupComplete />],
};

const PasswordSetting = () => {
  const searchParams = useSearchParams();

  const getToken = searchParams.get("token");

  const { data, isLoading } = getPasswordToken(getToken);
  const { setTab, currentStep } = useLoginSignUp();

  useEffect(() => {
    if (!isLoading) {
      const { email }: any = data;
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailRegex.test(email)) return setTab("login");
    }
  }, [isLoading]);

  console.log("PasswordSettingPage", data?.type);
  return (
    <S.Container>
      {data && PasswordSettingPage[data.type][currentStep]}
    </S.Container>
  );
};

export default PasswordSetting;

const S = {
  Container: styled("div")<any>(() => ({
    background: "white",
    borderRadius: "12px",
    width: "480px",
    height: "auto",
    padding: "48px",
    boxSizing: "border-box",
  })),
};
