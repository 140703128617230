/* eslint-disable no-unused-vars */
import { useCallback, useRef, useState } from "react";

interface EventSourceOptions {
  url: string;
  onMessage: (data: string) => void;
  onError?: (error: Event) => void;
}

const useEventSource = () => {
  const eventSourceRef = useRef<EventSource | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  const connect = useCallback(
    ({ url, onMessage, onError }: EventSourceOptions) => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }

      const eventSource = new EventSource(url);
      eventSourceRef.current = eventSource;

      eventSource.onopen = () => setIsConnected(true);
      eventSource.onmessage = (event) => {
        if (event.data === "[DONE]") {
          eventSource.close();
          setIsConnected(false);
        } else {
          onMessage(event.data);
        }
      };
      eventSource.onerror = (error) => {
        console.error("EventSource failed:", error);
        if (onError) onError(error);
        eventSource.close();
        setIsConnected(false);
      };
    },
    [],
  );

  const disconnect = useCallback(() => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      eventSourceRef.current = null;
      setIsConnected(false);
    }
  }, []);

  return { connect, disconnect, isConnected };
};

export default useEventSource;
