import { apiInstance } from "_utils/axiosInstance";

import { CreateUserForm } from "./user.types";

export const createUser = async (form: CreateUserForm) => {
  try {
    const { data } = await apiInstance.post("user", form);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export async function findOneByEmail(email: any) {
  try {
    const { data } = await apiInstance.get("user/email?email=" + email);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function updatePassword(updateUserDto: {
  email: string;
  password: string;
}) {
  try {
    const { data } = await apiInstance.patch(
      `user/password-reset`,
      updateUserDto,
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}
