import React from "react";
import { Button } from "_components/Button";
import Typography from "_components/Typography";

import Login from "./Login";
import Password from "./Password";
import PasswordReset from "./PasswordReset";

export type LoginStepObject = {
  title: string;
  page: React.ReactNode;
  button: (props: {
    handleNextStep: () => void;
    emailCheck?: () => void;
    sendEmail?: () => void;
  }) => React.ReactNode;
  subContents?: (props: {
    handleNextStep?: () => void;
    setTab?: (tab: "login" | "sign") => void;
  }) => React.ReactNode;
};

export const loginStepObj: LoginStepObject[] = [
  {
    title: "PLWIDA에 오신 것을 환영합니다.",
    page: <Login />,
    button: ({ emailCheck }) => (
      <Button
        type="button"
        variant="default"
        size="md"
        width="100%"
        onClick={emailCheck}
      >
        <Typography variant="body.200" color="text_60">
          이메일로 로그인
        </Typography>
      </Button>
    ),
    subContents: ({ setTab }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body.200" color="text_15">
          PLWIDA를 처음 이용하시나요?
        </Typography>
        <Button
          type="button"
          variant="label"
          size="md"
          onClick={() => setTab("sign")}
        >
          <Typography variant="body.201" color="blue_900">
            계정 생성
          </Typography>
        </Button>
      </div>
    ),
  },
  {
    title: "로그인",
    page: <Password />,
    button: () => {
      return (
        <Button variant="default" size="md" width="100%">
          <Typography variant="body.200" color="text_60">
            로그인
          </Typography>
        </Button>
      );
    },
    subContents: ({ handleNextStep }) => (
      <Button
        type="button"
        style={{ padding: "0" }}
        variant="label"
        size="md"
        onClick={handleNextStep}
      >
        <Typography variant="body.201" color="blue_900">
          비밀번호를 잃어버리셨나요?
        </Typography>
      </Button>
    ),
  },
  {
    title: "비밀번호 재설정",
    page: <PasswordReset />,
    button: ({ sendEmail }) => (
      <Button
        type="button"
        variant="line"
        size="md"
        width="100%"
        onClick={sendEmail}
      >
        <Typography variant="body.200" color="primary_main">
          비밀번호 재설정 메일 요청
        </Typography>
      </Button>
    ),
  },
];
