import kuraLogo from "_assets/icons/ic_kura_logo_46.png";
import Typography from "_components/Typography";
import Image from "next/image";
import styled from "styled-components";

const Header = () => (
  <S.KuraHeader>
    <S.KuraLogo alt="kura-logo" src={kuraLogo} width={46} height={46} />

    <S.TextWrapper>
      <Typography variant="subtitle.200_sb" color="text_10">
        Kura Assist
      </Typography>
      <Typography variant="body.300" color="text_10">
        Kura가 오늘의 리포트를 알려드립니다
      </Typography>
    </S.TextWrapper>
  </S.KuraHeader>
);

export default Header;

const S = {
  KuraHeader: styled("div")({
    backgroundColor: "var(--white)",
    color: "white",
    display: "flex",
    alignItems: "center",
    marginBottom: "24px",
    marginTop: "13px",
  }),
  TextWrapper: styled("div")({
    display: "flex",
    flexDirection: "column",
  }),
  KuraLogo: styled(Image)({
    marginLeft: "10px",
    marginRight: "30px",
  }),
};
