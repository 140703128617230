"use client";

import React, { PropsWithChildren } from "react";
import { illust } from "_styles/shared/icons";
import Image from "next/image";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import styled from "styled-components";

import Fade from "./Fade";
import SnackBarIcon from "./SnackBarIcon";

import "./SnackBar.css";

export const SnackBarProvider = ({ children }: PropsWithChildren) => {
  return (
    <div>
      <SnackbarProvider
        style={{
          borderRadius: "10px",
        }}
        TransitionComponent={Fade}
        autoHideDuration={1500}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
          warning: StyledMaterialDesignContent,
          info: StyledMaterialDesignContent,
          default: StyledMaterialDesignContent,
        }}
        iconVariant={{
          success: <SnackBarIcon variant="success" />,
          error: <SnackBarIcon variant="error" />,
          warning: <SnackBarIcon variant="warning" />,
          info: <SnackBarIcon variant="info" />,
          default: <SnackBarIcon variant="default" />,
        }}
      >
        {children}
      </SnackbarProvider>
    </div>
  );
};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  backdropFilter: "blur(2px)",
  border: "1px solid #63787D",
  background: " #363A47 !important",
  justifyContent: "center",
  minWidth: "auto !important",
  overflow: "hidden",
  padding: "9px 0",
  margin: 0,
}));

export default SnackBarProvider;
