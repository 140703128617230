/* eslint-disable no-unused-vars */
import colorToken from "_styles/colorToken";
import typography from "_styles/typography";
import { usePathname } from "next/navigation";
import styled from "styled-components";

import { MenuProps, StyledProps } from "./types";

interface SubMunuProps extends MenuProps {
  onClickMenu: (href) => void;
}

function getSubPath(pathname) {
  if (pathname === "/") return "home";
  return pathname.split("/")[2];
}

const SubMenuItem = ({ category, onClickMenu }: SubMunuProps) => {
  const pathname = usePathname();
  const path = getSubPath(pathname);
  const isSelected = path === category.key;

  return (
    <S.SubMenuItem
      onClick={() => onClickMenu(category.href)}
      isSelected={isSelected}
    >
      <div style={{}}>
        <S.SubMenuItemLabel selected={isSelected}>
          {category.label}
        </S.SubMenuItemLabel>
      </div>
    </S.SubMenuItem>
  );
};

const S = {
  SubMenuItem: styled("div")(({ isSelected }: StyledProps) => ({
    display: "flex",
    gap: "8px",
    padding: "8px 24px",
    backgroundColor: isSelected ? "rgba(255, 255, 255, 0.07)" : "",
    borderRadius: isSelected ? "8px" : "",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.07)",
      borderRadius: "8px",
    },
  })),
  SubMenuItemLabel: styled("span")<any>((props: any) => ({
    ...typography["body.301"],
    color: props.selected ? colorToken.text_60 : colorToken.gray_500,
    whiteSpace: "nowrap",
  })),
};

export default SubMenuItem;
