import React, { useState } from "react";
import Input from "_components/Input/Input";
import Typography from "_components/Typography";
import styled from "styled-components";

const PasswordReset = (props: any) => {
  const { watch } = props;

  const email = watch("email");

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "34px" }}>
        <Input
          label={
            <Typography variant="caption.100" color="text_20">
              이메일
            </Typography>
          }
          value={email}
          inputWrapperStyle={{ opacity: 1 }}
          placeholder={"인증번호를 입력해주세요."}
          disabled
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          marginTop: "12px",
        }}
      >
        <div>
          <Typography variant="body.200" color={"text_5"}>
            연결된 정보를 확인하여 해당 계정으로 비밀번호 재설정 이메일을
            발송해드립니다.
          </Typography>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body.200_sb" color={"text_15"}>
            유의사항
          </Typography>
          <Typography variant="body.200" color={"text_15"}>
            유의사항 요청 후 메일이 도착하지 않았다면 스팸함을 확인해주세요.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;

const S = {
  Container: styled("div")<any>(() => ({
    padding: "48px ",
    background: "white",
    borderRadius: "12px",
  })),
  Title: styled("div")<any>(() => ({
    marginBottom: "28px",
  })),
  SocialLogin: styled("div")<any>(() => ({
    width: "380px",
    padding: "9px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    border: "1px solid var(--btn-btn2-N, rgba(0, 0, 0, 0.05))",
    borderRadius: "4px",
    cursor: "pointer",
  })),
  SocialLoginContainer: styled("div")<any>(() => ({
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  })),
  Line: styled("div")<any>(() => ({
    flex: 1,
    background: "var(--line-10, #F2F3F5)",
    height: "1px",
  })),
  LineContainer: styled("div")<any>(() => ({
    display: "flex",
    gap: "16px",
    alignItems: "center",
    padding: "16px",
  })),
  ButtonContainer: styled("div")<any>(() => ({
    marginTop: "34px",
    marginBottom: "12px",
  })),
};
