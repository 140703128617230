import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

const BASE_URL = `${process.env.NEXT_PUBLIC_API_URL}`;

const apiClient = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

// 에러 처리 함수
export const handleApiError = (error: AxiosError) => {
  if (error.response) {
    const { status, data } = error.response as AxiosResponse;
    const isNotFoundCookie = data.message === "NotFoundCookie";

    switch (status) {
      case 400:
        console.error("Bad Request:", data);
        break;
      case 401:
        console.error("Unauthorized:", data);
        break;
      case 403:
        console.error("Forbidden:", data);
        break;
      case 404:
        if (isNotFoundCookie) {
          console.warn("Cookie not found");
        } else {
          console.error("Not Found:", data);
        }
        break;
      case 500:
        console.error("Server Error:", data);
        break;
      default:
        console.error(`Unhandled error status: ${status}`, data);
    }
  } else if (error.request) {
    console.error("No response received:", error.request);
  } else {
    console.error("Error setting up request:", error.message);
  }

  return Promise.reject(error);
};

apiClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => handleApiError(error),
);

export const apiInstance = apiClient;
