import { apiInstance } from "_utils/axiosInstance";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import useSWR, { KeyedMutator, SWRConfiguration } from "swr";

import { API_CONFIG, ApiConfigKey } from "./api-config";

interface UseApiReturn<T> {
  data: T | undefined;
  isLoading: boolean;
  isError: AxiosError | null;
  mutate: KeyedMutator<T>;
}

const fetcher = async <K extends ApiConfigKey>(
  apiKey: K,
  path: string,
  params?: Record<string, any>,
): Promise<any> => {
  const baseUrl = API_CONFIG[apiKey];
  if (!baseUrl) {
    throw new Error(`API URL for ${apiKey} is not defined`);
  }
  const url = `${path}`;
  const config: AxiosRequestConfig = { params };
  const { data } = await apiInstance.get(url, config);
  return data;
};
/**
 * 
 * @example
  선언부에서 아래와 같이 사용할 수 있습니다.

  export function useS3ConnectionList(params?: S3ConnectionListParams) {
    return useApi<S3Connection[]>("MAIN", "/external-storage/s3-connection", params);
  }

  호출부에서 아래와 같이 사용할 수 있습니다.

  const { data: connections, mutate, isLoading, isError } = useS3ConnectionList({
    limit: 10,
    offset: 0,
    sortBy: 'name'
  });
 */

export function useApi<T, K extends ApiConfigKey = ApiConfigKey>(
  apiKey: K,
  path: string,
  params?: Record<string, any>,
  config?: SWRConfiguration,
): UseApiReturn<T> {
  const { data, error, isLoading, mutate } = useSWR<T, AxiosError>(
    params ? [apiKey, path, params] : [apiKey, path],
    ([apiKey, path, params]) => fetcher(apiKey, path, params),
    config,
  );

  return {
    data,
    isLoading,
    isError: error,
    mutate,
  };
}
