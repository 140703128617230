import { useState } from "react";
import { Button } from "_components/Button";
import Input from "_components/Input/Input";
import Typography from "_components/Typography";
import useSnackBar from "_hooks/SnackBar/useSnackBar";
import useLoginSignUp from "_hooks/use-login-sign-up";
import { sendEmailToUser } from "_libs/fetcher/auth";
import { illust } from "_styles/shared/icons";
import Image from "next/image";
import styled from "styled-components";

const EmailPost = (props: any) => {
  const { setTab } = useLoginSignUp();

  const { email } = props;

  const { open } = useSnackBar();
  const SendEmail = async () => {
    const result = await sendEmailToUser({ email: email, type: "setting" });

    if (result.isSent === true) {
      open({
        text: "인증 메일이 재발송되었습니다.",
        variant: "success",
      });
    }

    console.log(result);
  };

  return (
    <>
      <div
        style={{
          width: "65px",
          height: "36px",
          marginBottom: "16px",
        }}
      >
        <Image alt="logo" src={illust.email} width={65} height={36} />
      </div>
      <S.Title>
        <Typography variant="title.200_sb" color={"text_5"}>
          인증 메일이 발송되었습니다.
        </Typography>
      </S.Title>
      <div style={{ marginBottom: "20px" }}>
        <Input disabled inputWrapperStyle={{ opacity: 1 }} value={email} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          marginBottom: "24px",
        }}
      >
        <Typography variant="body.200" color={"text_5"}>
          위 이메일의 메일함에서 인증 메일을 확인 바랍니다. 이메일의 인증버튼을
          선택하면 회원가입이 완료됩니다.
        </Typography>
        <Typography variant="body.200" color={"text_15"}>
          <Typography variant="body.200_sb" color={"text_15"}>
            유의사항
            <br />
          </Typography>
          메일이 도착하지 않았다면 스팸함을 확인해주세요.
          <br /> 메일 재발송이 필요한 경우 아래 버튼을 눌러주세요.
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button variant="line" size="md" onClick={SendEmail}>
          <Typography variant="body.200" color="primary_main">
            인증 메일 재발송
          </Typography>
        </Button>
        <Button variant="label" size="md" onClick={() => setTab("login")}>
          <Typography variant="body.201" color="blue_900">
            로그인으로 돌아가기
          </Typography>
        </Button>
      </div>
    </>
  );
};

export default EmailPost;

const S = {
  Container: styled("div")<any>(() => ({
    padding: "48px",
    width: "470px",
    background: "white",
    borderRadius: "12px",
    overflow: "hidden",
    boxSizing: "border-box",
  })),

  Title: styled("div")<any>(() => ({
    marginBottom: "28px",
  })),
  SocialLogin: styled("div")<any>(() => ({
    width: "380px",
    padding: "9px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    border: "1px solid var(--btn-btn2-N, rgba(0, 0, 0, 0.05))",
    borderRadius: "4px",
    cursor: "pointer",
  })),
  SocialLoginContainer: styled("div")<any>(() => ({
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  })),
  Line: styled("div")<any>(() => ({
    flex: 1,
    background: "var(--line-10, #F2F3F5)",
    height: "1px",
  })),
  LineContainer: styled("div")<any>(() => ({
    display: "flex",
    gap: "16px",
    alignItems: "center",
    padding: "16px",
  })),
  ButtonContainer: styled("div")<any>(() => ({
    marginTop: "16px",
    marginBottom: "12px",
  })),
};
