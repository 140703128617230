import React, { useState } from "react";
import Input from "_components/Input/Input";
import { Tooltip } from "_components/Tooltip";
import Typography from "_components/Typography";
import { icons } from "_styles/shared/icons";
import { hasNumber, hasSpecialChar, isLengthValid } from "_utils/password";
import Image from "next/image";

const SetupOne = (props) => {
  const { watch, setValue } = props;

  const email = watch("email");
  const password = watch("password");
  const passwordConfirm = watch("passwordConfirm");

  const [isPasswordFocus, setIsPasswordFocus] = useState(false);
  const [isPasswordConfirmFocus, setIsPasswordConfirmFocus] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordConfirmVisible, setIsPasswordConfirmVisible] =
    useState(false);

  const [isPasswordCheck, setIsPasswordCheck] = useState({
    isLengthValid: false,
    hasSpecialChar: false,
    hasNumber: false,
  });
  const [isPasswordConfirmCheck, setIsPasswordConfirmCheck] = useState({
    isLengthValid: false,
    hasSpecialChar: false,
    hasNumber: false,
  });

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handlePasswordOnChange = (e: any) => {
    setValue("password", e.target.value);
    setIsPasswordCheck({
      isLengthValid: isLengthValid(e.target.value),
      hasSpecialChar: hasSpecialChar(e.target.value),
      hasNumber: hasNumber(e.target.value),
    });
  };

  const handlePasswordConfirmOnChange = (e: any) => {
    if (password !== e.target.value) {
      setError(true);
      setErrorMessage("비밀번호가 일치하지 않습니다.");
    } else {
      setError(false);
      setErrorMessage("");
    }

    setValue("passwordConfirm", e.target.value);

    setIsPasswordConfirmCheck({
      isLengthValid: isLengthValid(e.target.value),
      hasSpecialChar: hasSpecialChar(e.target.value),
      hasNumber: hasNumber(e.target.value),
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Input
        label={
          <Typography variant="caption.100" color="text_20">
            이메일
          </Typography>
        }
        value={email}
        inputWrapperStyle={{ opacity: 1 }}
        endAdornment={
          <div
            style={{
              padding: "3px 8px",
              background: " var(--primary_main_10dp)",
              borderRadius: "8px",
            }}
          >
            <Typography variant="body.300" color="primary_main">
              인증완료
            </Typography>
          </div>
        }
        disabled
      />
      <Tooltip
        open={isPasswordFocus}
        content={
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography variant="caption.100" color={"white"}>
              비밀번호 필수 조건
            </Typography>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <CheckTooltip
                contents={"8자 이상 20자 이하"}
                check={isPasswordCheck.isLengthValid}
              />
              <CheckTooltip
                contents={"특수문자 조합 사용"}
                check={isPasswordCheck.hasSpecialChar}
              />
              <CheckTooltip
                contents={"숫자 조합 사용"}
                check={isPasswordCheck.hasNumber}
              />
            </div>
          </div>
        }
        sideOffset={10}
        side="right"
      >
        <Input
          onFocus={() => {
            setIsPasswordFocus(true);
          }}
          onBlur={() => {
            setIsPasswordFocus(false);
          }}
          type={isPasswordVisible ? "text" : "password"}
          label={
            <Typography variant="caption.100" color="text_20">
              비밀번호
            </Typography>
          }
          endAdornment={
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsPasswordVisible(!isPasswordVisible);
              }}
            >
              {isPasswordVisible ? (
                <Image src={icons.eye.on} alt="eye" width={20} height={20} />
              ) : (
                <Image src={icons.eye.off} alt="eye" width={20} height={20} />
              )}
            </div>
          }
          value={password}
          placeholder={"비밀번호를 입력해주세요."}
          onChange={handlePasswordOnChange}
        />
      </Tooltip>
      <Tooltip
        open={isPasswordConfirmFocus}
        content={
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography variant="caption.100" color={"white"}>
              비밀번호 필수 조건
            </Typography>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <CheckTooltip
                contents={"8자 이상 20자 이하"}
                check={isPasswordConfirmCheck.isLengthValid}
              />
              <CheckTooltip
                contents={"특수문자 조합 사용"}
                check={isPasswordConfirmCheck.hasSpecialChar}
              />
              <CheckTooltip
                contents={"숫자 조합 사용"}
                check={isPasswordConfirmCheck.hasNumber}
              />
            </div>
          </div>
        }
        sideOffset={10}
        side="right"
      >
        <Input
          onFocus={() => {
            setIsPasswordConfirmFocus(true);
          }}
          onBlur={() => {
            setIsPasswordConfirmFocus(false);
          }}
          error={error}
          errorMessage={errorMessage}
          type={isPasswordConfirmVisible ? "text" : "password"}
          label={
            <Typography variant="caption.100" color="text_20">
              비밀번호 확인
            </Typography>
          }
          endAdornment={
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsPasswordConfirmVisible(!isPasswordConfirmVisible);
              }}
            >
              {isPasswordConfirmVisible ? (
                <Image src={icons.eye.on} alt="eye" width={20} height={20} />
              ) : (
                <Image src={icons.eye.off} alt="eye" width={20} height={20} />
              )}
            </div>
          }
          value={passwordConfirm}
          placeholder={"비밀번호를 입력해주세요."}
          onChange={handlePasswordConfirmOnChange}
        />
      </Tooltip>
    </div>
  );
};

export default SetupOne;

const CheckTooltip = (props: any) => {
  const { check, contents } = props;

  return (
    <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
      <Image
        src={check ? icons.check.main_20 : icons.check.white_16}
        width={12}
        height={12}
        alt=""
      />
      <Typography variant="caption.100" color={check ? "white" : "text_30"}>
        {contents}
      </Typography>
    </div>
  );
};
