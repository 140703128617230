import React from "react";
import Typography from "_components/Typography";

import SetupOne from "./SetupOne";
import SetupTwo from "./SetupTwo";

export type SetupStepObject = {
  title: any;
  page: React.ReactNode;
};

export const SetupStepObj: SetupStepObject[] = [
  {
    title: (
      <>
        <Typography variant="title.200_sb" color="text_5">
          로그인에 사용할
        </Typography>
        <Typography variant="title.200_sb" color="text_5">
          비밀번호를 입력해주세요.
        </Typography>
      </>
    ),
    page: <SetupOne />,
  },
  {
    title: (
      <>
        <Typography variant="title.200_sb" color="text_5">
          계정 소유자의
        </Typography>
        <Typography variant="title.200_sb" color="text_5">
          정보를 입력해주세요.
        </Typography>
      </>
    ),
    page: <SetupTwo />,
  },
];
