"use client";

import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from "react";
import { ModalContext } from "_libs/context/Modal";

export const ModalProvider = ({ children }: PropsWithChildren) => {
  const [modalById, setModalById] = useState<Map<string, ReactNode>>(new Map());

  const modalOpen = useCallback((id: string, element: ReactNode) => {
    setModalById((modalById) => {
      const cloned = new Map(modalById);
      cloned.set(id, element);
      return cloned;
    });
  }, []);

  const modalClose = useCallback((id: string) => {
    setModalById((modalById) => {
      const cloned = new Map(modalById);
      cloned.delete(id);
      return cloned;
    });
  }, []);

  return (
    <ModalContext.Provider value={{ modalOpen, modalClose }}>
      {children}
      {Array.from(modalById.entries()).map(([id, element]) => (
        <React.Fragment key={id}>{element}</React.Fragment>
      ))}
    </ModalContext.Provider>
  );
};
