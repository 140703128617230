/* eslint-disable turbo/no-undeclared-env-vars */

export const API_CONFIG = {
  MAIN: process.env.NEXT_PUBLIC_API_URL || "",
  // S3: process.env.REACT_APP_S3_API_URL || "",
  // AUTH: process.env.REACT_APP_AUTH_API_URL || "",
  // 필요한 만큼 API URL을 추가할 수 있습니다.
} as const;

export type ApiConfigKey = keyof typeof API_CONFIG;
