import React from "react";
import styled from "styled-components";

const Container = ({ children }: { children: React.ReactNode }) => (
  <S.ChatbotContainer>{children}</S.ChatbotContainer>
);

export default Container;

const S = {
  ChatbotContainer: styled("div")({
    position: "fixed",
    bottom: "110px",
    right: "40px",
    width: "400px",
    height: "calc(100vh - 180px)",
    backgroundColor: "var(--white)",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    overflow: "visible",
    padding: "20px 16px 20px 16px",
  }),
};
