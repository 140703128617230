import { useContext, useState } from "react";
import Modal from "_components/Modal";
import { ModalOpenProps } from "_components/Modal/types";
import { ModalContext } from "_libs/context/Modal";

let elementId = 1;

/**
 * 모달을 생성하는 훅
 *
 * @param props.type - 모달의 타입.(필수)
 * @param props.otherProps - 모달마다 필요한 props
 * @example
 * const { open : 사용 모달 이름, close } = useModal({ type: 타입 , ...otherProps });
 */
export const useModal = () => {
  const modalContext = useContext(ModalContext);

  if (modalContext == null) {
    throw new Error("useModal is only available within ModalProvider.");
  }

  const { modalOpen: onOpen, modalClose: onClose } = modalContext;

  const [id] = useState(() => String(elementId++));

  return {
    open: (props: ModalOpenProps) => {
      const { type, ...otherProps } = props;

      onOpen(
        id,
        <Modal
          {...otherProps}
          key={Date.now()}
          close={() => {
            onClose(id);
          }}
          type={type}
        />,
      );
    },
    close: () => {
      onClose(id);
    },
  };
};
