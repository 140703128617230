import React, { forwardRef, useCallback, useState } from "react";
import { icons } from "_styles/shared/icons";
import { theme } from "_styles/theme";
import Image from "next/image";
import styled from "styled-components";

import InputBase from "./Input";
import { InputBaseProps } from "./types";

interface SearchInputProps extends InputBaseProps {
  filled?: boolean; //Todo : filled 타입 디자인 나오면 추가해야함
}

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  function SearchInput(props, ref) {
    const { onChange, disabled = false, ...rest } = props;
    const [inputValue, setInputValue] = useState("");

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(e);
          setInputValue(e.target.value);
        }
      },
      [onChange],
    );

    const onSearch = (data: string) => {
      console.log("onSearch", data);
    };

    const handleSearch = useCallback(
      (value: string) => {
        onSearch(value);
      },
      [onSearch],
    );

    const handleKeyPress = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
          handleSearch((e.target as HTMLInputElement).value);
        }
      },
      [handleSearch],
    );

    return (
      <InputBase
        ref={ref}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        disabled={disabled}
        endAdornment={
          <S.SearchIcon
            src={icons.search.bgray400_20}
            alt="search-icon"
            width={20}
            height={20}
            disabled={disabled}
            draggable={false}
            onClick={() => handleSearch(inputValue)}
          />
        }
        placeholderStyle={{
          color: theme("light").palette.text_30,
          ...theme("light").typography["caption.100"],
        }}
        inputStyle={{
          padding: "6px 8px",
        }}
        inputWrapperStyle={{
          background: theme("light").palette.white,
          borderColor: theme("light").palette.line_10, // 여기에 새로운 borderColor 추가
        }}
        size="32"
        {...rest}
      />
    );
  },
);

const S = {
  SearchIcon: styled(Image)<any>((props: any) => ({
    cursor: !props.disabled && "pointer",
  })),
};

export default SearchInput;
