import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Slider from "react-slick";
import { Button } from "_components/Button";
import Typography from "_components/Typography";
import useSnackBar from "_hooks/SnackBar/useSnackBar";
import useLoginSignUp from "_hooks/use-login-sign-up";
import { createUser } from "_libs/fetcher/user";
import { getPasswordToken } from "_libs/swr/auth/auth";
import { icons } from "_styles/shared/icons";
import { isPasswordValid } from "_utils/password";
import Image from "next/image";
import { useSearchParams } from "next/navigation";
import styled from "styled-components";

import { SetupStepObj, SetupStepObject } from "./SetupConfig";

const initData = {
  email: "",
  password: "",
  passwordConfirm: "",
  name: "",
  companyName: "",
};

const checkArray = [
  ["password", "passwordConfirm"],
  ["name", "companyName"],
];

const Setup = () => {
  const { open } = useSnackBar();

  const { handleNextStep, setTab } = useLoginSignUp();

  const { watch, setValue, handleSubmit } = useForm({
    defaultValues: initData,
  });

  const searchParams = useSearchParams();
  const getToken = searchParams.get("token");

  const { data } = getPasswordToken(getToken);

  useEffect(() => {
    if (data) {
      setValue("email", data.email);
    }
  }, [data]);

  const handleSignUp = async (props: any) => {
    const result = await createUser(props);
    if (result) {
      handleNextStep();
    } else {
      open({
        variant: "error",
        text: "회원가입에 실패했습니다.",
      });
    }
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextStep = () => {
    if (currentSlide < SetupStepObj.length - 1) {
      sliderRef.current?.slickNext();
    }
  };

  const prevStep = () => {
    if (currentSlide > 0) {
      sliderRef.current?.slickPrev();
    }
  };

  const sliderRef = useRef<Slider>(null);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    fade: false,
    beforeChange: (_: number, newIndex: number) => setCurrentSlide(newIndex),
  };

  const passwordCheckOrCompanyNameCheck = () => {
    const password = watch("password");
    const passwordConfirm = watch("passwordConfirm");

    if (!checkArray[currentSlide].every((key: any) => isEntered(watch(key))))
      return open({
        variant: "error",
        text: "내용을 입력해주세요.",
      });

    if (!isPasswordValid(password) || !isPasswordValid(passwordConfirm)) {
      return open({
        variant: "error",
        text: "비밀번호 필수 조건이 적합하지 않습니다.",
      });
    }

    if (password !== passwordConfirm) {
      return open({
        variant: "error",
        text: "비밀번호가 일치하지 않습니다.",
      });
    }
    return nextStep();
  };

  const isEntered = (data: string) => {
    return data && data.trim() !== "";
  };

  const currentStep: SetupStepObject = SetupStepObj[currentSlide];

  return (
    <form onSubmit={handleSubmit(handleSignUp)}>
      <div style={{ display: "flex", justifyContent: "end", gap: "12px" }}>
        {SetupStepObj.map((item, index) => (
          <div
            key={index}
            style={{
              width: "21px",
              height: "21px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background:
                index === currentSlide
                  ? "var(--primary_main)"
                  : index < currentSlide
                    ? "var(--primary_main_15dp)"
                    : "var(--background_default)",
              borderRadius: "50%",
            }}
          >
            {index < currentSlide ? (
              <Image src={icons.check.main_20} width={16} height={16} alt="" />
            ) : (
              <Typography
                variant="caption.200_sb"
                color={index === currentSlide ? "white" : "text_40"}
              >
                {index + 1}
              </Typography>
            )}
          </div>
        ))}
      </div>
      <S.Title>{currentStep.title}</S.Title>

      <Slider ref={sliderRef} {...settings}>
        {SetupStepObj.map((item: any, index) => (
          <div key={index}>
            {React.cloneElement(item.page, {
              watch,
              setValue,
            })}
          </div>
        ))}
      </Slider>

      <S.ButtonContainer>
        <Button
          startIcon={
            <Image
              src={icons.chevron.left.bgray200_16}
              width={16}
              height={16}
              alt=""
            />
          }
          style={{
            opacity: currentSlide <= 0 ? 0 : 1,
            cursor: currentSlide <= 0 ? "default" : "pointer",
          }}
          type="button"
          variant="label"
          size="md"
          onClick={prevStep}
        >
          <Typography variant="body.201" color={"text_10"}>
            이전
          </Typography>
        </Button>

        <Button
          type={currentSlide === 0 ? "button" : "submit"}
          onClick={passwordCheckOrCompanyNameCheck}
          variant="default"
          size="md"
          width="84px"
          disabled={checkArray[currentSlide].some((key: any) => !watch(key))}
        >
          <Typography variant="body.201">
            {currentSlide === 0 ? "다음" : "완료"}
          </Typography>
        </Button>
      </S.ButtonContainer>
    </form>
  );
};

export default Setup;

const S = {
  Title: styled("div")<any>(() => ({
    display: "flex",
    flexDirection: "column",
    marginBottom: "28px",
  })),
  SocialLogin: styled("div")<any>(() => ({
    width: "380px",
    padding: "9px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    border: "1px solid var(--btn-btn2-N, rgba(0, 0, 0, 0.05))",
    borderRadius: "4px",
    cursor: "pointer",
  })),
  SocialLoginContainer: styled("div")<any>(() => ({
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  })),
  Line: styled("div")<any>(() => ({
    flex: 1,
    background: "var(--line-10, #F2F3F5)",
    height: "1px",
  })),
  LineContainer: styled("div")<any>(() => ({
    display: "flex",
    gap: "16px",
    alignItems: "center",
    padding: "16px",
  })),
  ButtonContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 36px;
  `,
};
