import useLoginSignUpTabStore, {
  TabType,
} from "../_stores/useLoginSignUpTabStore";

const useLoginSignUp = () => {
  const {
    currentTab,
    loginStep,
    signUpStep,
    settingStep,
    setTab: originalSetTab,
    setLoginStep,
    setSignUpStep,
    setSettingStep,
    resetSteps,
  } = useLoginSignUpTabStore();

  const handleNextStep = () => {
    switch (currentTab) {
      case "login":
        setLoginStep(loginStep + 1);
        break;
      case "sign":
        setSignUpStep(signUpStep + 1);
        break;
      case "setting":
        setSettingStep(settingStep + 1);
        break;
    }
  };

  const handlePreviousStep = () => {
    switch (currentTab) {
      case "login":
        setLoginStep(loginStep - 1);
        break;
      case "sign":
        setSignUpStep(signUpStep - 1);
        break;
      case "setting":
        setSettingStep(settingStep - 1);
        break;
    }
  };

  const setTab = (tab: TabType) => {
    originalSetTab(tab);
    resetSteps();
  };

  const handlePasswordStep = () => {
    setTab("setting");
  };

  const getCurrentStep = () => {
    switch (currentTab) {
      case "login":
        return loginStep;
      case "sign":
        return signUpStep;
      case "setting":
        return settingStep;
      default:
        return 1;
    }
  };

  const getMaxSteps = () => {
    switch (currentTab) {
      case "login":
        return 3;
      case "sign":
        return 4;
      case "setting":
        return 2;
      default:
        return 1;
    }
  };

  const currentStep = getCurrentStep();
  const maxSteps = getMaxSteps();

  return {
    currentTab,
    currentStep,
    maxSteps,
    setTab,
    handleNextStep,
    handlePreviousStep,
    handlePasswordStep,
    resetSteps,
  };
};

export default useLoginSignUp;
