import { forwardRef } from "react";
import Typography from "_components/Typography";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import styled from "styled-components";

import { TooltipProps } from "./types";

type TooltipElement = React.ElementRef<typeof TooltipPrimitive.Content>;

/**
 * @example
 * <Tooltip title="text" side="right" sideOffset={5}>
 *   <button>trigger</button>
 * </Tooltip>
 *
 */

const Tooltip = forwardRef<TooltipElement, TooltipProps>((props, ref) => {
  const {
    children,
    open,
    defaultOpen,
    onOpenChange, // 툴팁의 열림 상태가 변경될 때 호출되는 콜백 함수
    delayDuration, // 툴팁이 나타나거나 사라질 때의 지연 시간
    disableHoverableContent,
    title, // 텍스트만 있는 툴팁
    content, // 모든 내용을 포함할 수 있음
    container,
    sideOffset, // 툴팁 트리거 사이 거리 조정
    side = "bottom",
    width,
    ...tooltipContentProps
  } = props;

  const rootProps = {
    open,
    defaultOpen,
    onOpenChange,
    delayDuration,
    disableHoverableContent,
  };

  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root {...rootProps} delayDuration={0}>
        <S.Trigger type="button">{children}</S.Trigger>
        <TooltipPrimitive.Portal container={container}>
          <S.Content
            {...tooltipContentProps}
            asChild={false}
            ref={ref}
            sideOffset={sideOffset}
            side={side}
            width={width}
          >
            {content}
            {title && (
              <Typography variant="caption.100" color="white">
                {title}
              </Typography>
            )}
            <TooltipPrimitive.Arrow />
          </S.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
});

Tooltip.displayName = "Tooltip";

const S = {
  Trigger: styled(TooltipPrimitive.Trigger)`
    all: unset;
    cursor: pointer;
  `,
  Content: styled(TooltipPrimitive.Content)<{ width: string }>`
    background: var(--snackbar);
    border-radius: 4px;
    padding: 6px 8px;
    width: ${(props) => props.width};
  `,
  Arrow: styled(TooltipPrimitive.Arrow)`
    path: {
      fill: red;
    }
  `,
};

export { Tooltip };
export type { TooltipProps };
